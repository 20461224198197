import { NotificationType, PlantAction, PlantData, plantStateToString, shortenAddress } from "@growerz/shared";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import AdminApiService from "../../../services/AdminApiService";
import { useNotification } from "../../../contexts/NotificationContext";

const ViewPlantModal = ({
    plant,
    disabled,
    refresh
}: {
    plant: PlantData
    disabled: boolean
    refresh: () => void
}) => {
    const { addErrorNotification, addNotification } = useNotification();
    const [showing, setShowing] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleWater = () => {
        setLoading(true);
        AdminApiService.post(`/plant/${plant.id}/${PlantAction.WATER}`).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification("Action succeeded", "Plant has been successfully watered", NotificationType.Success);
            refresh();
            hide();
        }).catch((error: any) => {
            addErrorNotification("Action failed", error.message);
            console.error(`Failed to water plant ${plant.id}: ${error.message}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleHarvest = () => {
        setLoading(true);
        AdminApiService.post(`/plant/${plant.id}/${PlantAction.HARVEST}`).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification("Action succeeded", `Plant has been successfully harvested for ${response.data.payout} THC`, NotificationType.Success);
            refresh();
            hide();
        }).catch((error: any) => {
            addErrorNotification("Action failed", error.message);
            console.error(`Failed to harvest plant ${plant.id}: ${error.message}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handlePrune = () => {
        setLoading(true);
        AdminApiService.post(`/plant/${plant.id}/${PlantAction.PRUNE}`).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification("Action succeeded", `Plant has been successfully pruned`, NotificationType.Success);
            refresh();
            hide();
        }).catch((error: any) => {
            addErrorNotification("Action failed", error.message);
            console.error(`Failed to harvest plant ${plant.id}: ${error.message}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    const show = () => {
        setShowing(true);
    }
    const hide = () => {
        setShowing(false);
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal">
            <Modal.Header closeButton closeVariant="white">
                <Row className="w-100">
                    <Col>
                        Plant Information
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center">
                    <Col>
                        Growhouse:
                        <br />
                        Owner:
                        <br />
                        Strain:
                        <br />
                        Rarity:
                        <br />
                        State:
                        <br />
                        Stage:
                        <br />
                        Last Watering Time:
                    </Col>
                    <Col className="text-end">
                        {plant.growhouse.id}
                        <br />
                        {shortenAddress(plant.growhouse.account.address)}
                        <br />
                        {plant.strain.name}
                        <br />
                        {plant.strain.rarity.name}
                        <br />
                        {plantStateToString(plant.state)}
                        <br />
                        {plant.stage}
                        <br />
                        {plant.last_watering_time ? new Date(plant.last_watering_time).toLocaleString() : 'N/A'}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" className="text-white mt-3 px-3" onClick={handleWater} disabled={loading}>Water</Button>
                <Button variant="success" className="text-white mt-3 px-3" onClick={handleHarvest} disabled={loading}>Harvest</Button>
                <Button variant="danger" className="text-white mt-3 px-3" onClick={handlePrune} disabled={loading}>Prune</Button>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Button variant={"thc"} className="text-white" onClick={show} size={"sm"} disabled={disabled}>View</Button>
    </>
}

export default ViewPlantModal;