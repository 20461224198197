import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useWallet } from "@solana/wallet-adapter-react";
import { NotificationType, PlantAction, PlantData, adjustClientDate, indexToRarity } from "@growerz/shared";

import useGrowhouse from "../../../hooks/useGrowhouse";

import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import { SeedImages } from "../../common/SeedImages";
import SeedTransferModal from "./SeedTransferModal";

const Seed = ({
    seed,
    disabled
}: {
    seed: PlantData,
    disabled: boolean
}) => {
    const [loading, setLoading] = useState(true);
    const { post } = useApi();
    const { publicKey } = useWallet();
    const { addErrorNotification, addNotification } = useNotification();
    const { refreshData } = useGrowhouse();

    useEffect(() => {
        setLoading(false);
    }, []);

    const handlePlant = (event: any) => {
        const seedId = event.currentTarget.value ?? event;
        const currentTime = adjustClientDate(new Date());
        if (publicKey) {
            setLoading(true);
            post(`/plant/${seedId}/${PlantAction.PLANT}`, {
                currentTime
            }).then((response) => {
                if (!response.success) {
                    addErrorNotification("Action failed!", `Unable to plant seed, ${response.message}`);
                    console.error("Failed to plant seed: " + response.message);
                } else {
                    addNotification("Action succeeded!", 'Seed planted successfully!', NotificationType.Success);
                    refreshData();
                }
                setLoading(false);
            }).catch((error) => {
                addErrorNotification("Action failed!", 'Unable to plant seed, contact support!');
                console.error("Failed to plant seed: " + error.message);
                setLoading(false);
            });
        }
    }

    const ActionButton = ({ seedId }: { seedId: number }) => {
        if (loading) {
            return <Button variant="danger" className='w-100' size="sm" disabled={loading}>
                Wait
            </Button>
        }

        return <Button variant="success" className='w-100' size="sm" onClick={handlePlant} value={seedId} disabled={disabled}>
            Plant
        </Button>
    }

    const SeedImage = () => {
        let rarity = indexToRarity(seed.strain.rarity.id);
        let img = SeedImages[rarity];
        return <img alt={rarity + " Seed"} src={img} height={32} />
    }

    return <>
        <tr className="d-none d-md-table-row">
            <td>{seed.id}</td>
            <td><SeedImage /></td>
            <td className={`text-start font-thumbs-down rarity-text-${seed.strain.rarity.name.toLowerCase()}`}>{seed.strain.rarity.name}</td>
            <td className='text-start'>{seed.strain.name}</td>
            <td>
                <Row>
                    <Col xs={6}>
                        <ActionButton seedId={seed.id} />
                    </Col>
                    <Col xs={6}>
                        <SeedTransferModal seed={seed} />
                    </Col>
                </Row>
            </td>
        </tr>
        <tr className="d-table-row d-md-none">
            <td></td>
            <td className={`text-start font-thumbs-down rarity-text-${seed.strain.rarity.name.toLowerCase()}`}>{seed.strain.rarity.name}</td>
            <td className='fs-7 text-center pt-3'>{seed.strain.name}</td>
            <td>
                <Row>
                    <Col>
                        <Button variant="success" className='w-100' size="sm" onClick={handlePlant} value={seed.id} disabled={disabled}>
                            Plant
                        </Button>
                    </Col>
                </Row>
            </td>
        </tr>
    </>
}

export default Seed;