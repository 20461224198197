import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { DispensarySeedData, PaymentMethod } from '@growerz/shared';

// Define the context
interface CartContextProps {
    cart: DispensarySeedData[],
    paymentMethod: PaymentMethod,
    total: number,
    addToCart: (seed: DispensarySeedData) => void,
    clearCart: () => void,
    getCount: (seedId: number) => number,
    removeFromCart: (seed: DispensarySeedData) => void,
    resetCart: () => void,
    togglePaymentMethod: () => void
}

const CartContext = createContext<CartContextProps>({
    cart: [],
    paymentMethod: PaymentMethod.THC,
    total: 0,
    addToCart: () => { },
    clearCart: () => { },
    getCount: () => 0,
    removeFromCart: () => { },
    resetCart: () => { },
    togglePaymentMethod: () => { }
});

// Define the custom hook
export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

// Define the provider component
export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.THC);
    const [cart, setCart] = useState<DispensarySeedData[]>([]);
    const [total, setTotal] = useState<number>(0);

    const addToCart = useCallback((seed: DispensarySeedData) => {
        setCart([...cart, seed]);
        if (paymentMethod === PaymentMethod.THC)
            setTotal(total + seed.cost);
        else if (paymentMethod === PaymentMethod.SOL)
            setTotal(total + Number(seed.sol));
    }, [cart, total, paymentMethod]);

    const removeFromCart = useCallback((seed: DispensarySeedData) => {
        const updatedCart = [...cart];
        let index = updatedCart.indexOf(seed);
        updatedCart.splice(index, 1);
        setCart(updatedCart);
        if (paymentMethod === PaymentMethod.THC)
            setTotal(total - seed.cost);
        else if (paymentMethod === PaymentMethod.SOL)
            setTotal(total - Number(seed.sol));
    }, [cart, total, paymentMethod]);

    const clearCart = useCallback(() => {
        setCart([]);
        setTotal(0);
    }, []);

    const resetCart = useCallback(() => {
        clearCart();
        setPaymentMethod(PaymentMethod.THC);
        setTotal(0);
    }, [clearCart]);

    const togglePaymentMethod = useCallback(() => {
        if (paymentMethod === PaymentMethod.THC) {
            setTotal(cart.reduce((a, b) => a + Number(b.sol), 0));
            setPaymentMethod(PaymentMethod.SOL);
        } else if (paymentMethod === PaymentMethod.SOL) {
            setTotal(cart.reduce((a, b) => a + b.cost, 0));
            setPaymentMethod(PaymentMethod.THC);
        }
    }, [cart, paymentMethod]);

    const getCount = useCallback((id: number) => {
        return cart.filter(seed => seed.id === id).length;
    }, [cart]);

    const value = useMemo(() => ({
        cart,
        paymentMethod,
        total,
        addToCart,
        clearCart,
        getCount,
        removeFromCart,
        resetCart,
        togglePaymentMethod
    }), [cart, paymentMethod, total, addToCart, clearCart, getCount, removeFromCart, resetCart, togglePaymentMethod]);

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
}

export default useCart;
