import { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";

import { useIdentity } from "../contexts/IdentityContext";
import { useNotification } from "../contexts/NotificationContext";
import UsersPanel from "../components/admin/Users";
import PlantsPanel from "../components/admin/Plants";
import LogsPanel from "../components/admin/Logs";
import GrowhousesPanel from "../components/admin/Growhouses";
import AdminsPanel from "../components/admin/Admins";
import GrowerzPanel from "../components/admin/Growerz";

const AdminPage = () => {
    const { addErrorNotification } = useNotification();
    const { identity, adminAuthenticate } = useIdentity();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        if (identity && !isAdmin && !isSuperAdmin) {
            setIsAdmin(identity.account.role?.admin ?? false);
            setIsSuperAdmin(identity.account.role?.superadmin ?? false);
        }
    }, [identity, isAdmin, isSuperAdmin]);

    const handleAuthenticate = async () => {
        adminAuthenticate().then(success => {
            if (!success) {
                addErrorNotification("Failed to authenticate", "Access Denied");
            }
        });
    }

    return (<Container className="py-3">
        <Row className="text-center mb-3">
            <Col>
                <h1>Growerz Hub Management</h1>
            </Col>
        </Row>
        <hr />
        {isAdmin && (<Tab.Container defaultActiveKey="dashboard">
            <Row>
                <Col sm={2}>
                    <Nav variant="pills" className="flex-column admin-nav text-center">
                        <Nav.Item>
                            <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="users">Users</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="plants">Plants & Seeds</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="growhouses">Growhouses</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="growerz">Growerz</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="logs">Logs</Nav.Link>
                        </Nav.Item>
                        {isSuperAdmin && (<Nav.Item>
                            <Nav.Link eventKey="admins">Admins</Nav.Link>
                        </Nav.Item>)}
                    </Nav>
                </Col>
                <Col sm={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="dashboard">
                            <h2>Dashboard Statistics</h2>
                        </Tab.Pane>
                        <Tab.Pane eventKey="users">
                            <UsersPanel />
                        </Tab.Pane>
                        <Tab.Pane eventKey="plants">
                            <PlantsPanel />
                        </Tab.Pane>
                        <Tab.Pane eventKey="growhouses">
                            <GrowhousesPanel />
                        </Tab.Pane>
                        <Tab.Pane eventKey="growerz">
                            <GrowerzPanel />
                        </Tab.Pane>
                        <Tab.Pane eventKey="logs">
                            <LogsPanel />
                        </Tab.Pane>
                        {isSuperAdmin && (
                            <Tab.Pane eventKey="admins">
                                <AdminsPanel />
                            </Tab.Pane>
                        )}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>)}
        {!isAdmin && (<Row>
            <Col>
                <Button onClick={handleAuthenticate}>
                    Authenticate
                </Button>
            </Col>
        </Row>)}
    </Container>);
}

export default AdminPage;
