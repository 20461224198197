import { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { LogData, shortenAddress } from "@growerz/shared";
import { SlMagnifier } from "react-icons/sl";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";
import PaginationComponent from "../common/Pagination";
import { messageFormatter } from "../../helpers/LogHelper";

const LogsPanel = () => {
    const { addErrorNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState<LogData[]>();
    const [totalLogs, setTotalLogs] = useState<number>(0);

    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(25);

    const [searchText, setSearchText] = useState();
    const [searchAddress, setSearchAddress] = useState();

    let now = new Date();
    const [endDate, setEndDate] = useState(now.toISOString().split('T')[0]);
    const [startDate, setStartDate] = useState(new Date(now.setDate(now.getDate() - 7)).toISOString().split('T')[0]);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.post('/logs', {
            skip,
            count,
            start: startDate,
            end: endDate,
            searchText,
            searchAddress
        }).then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.total !== totalLogs) setTotalLogs(response.data.total);
            setLogs(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get plant data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, startDate, endDate, skip, count, searchText, searchAddress, totalLogs]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const updateStartDate = (event: any) => {
        const newDate = new Date(event.currentTarget.value);
        setStartDate(newDate.toISOString().split('T')[0]);
        setSkip(0);
    }

    const updateEndDate = (event: any) => {
        const newDate = new Date(event.currentTarget.value);
        setEndDate(newDate.toISOString().split('T')[0]);
        setSkip(0);
    }

    const updateSearchText = (event: any) => {
        setSearchText(event.currentTarget.value);
        setSkip(0);
    }

    const updateSearchAddress = (event: any) => {
        setSearchAddress(event.currentTarget.value);
        setSkip(0);
    }

    const updateCount = (event: any) => {
        setCount(event.currentTarget.value);
        setSkip(0);
    }

    const handlePageChange = (page: number) => {
        setSkip((page * count) - count);
    };

    const Logs = () => {
        if (loading) return (<tr>
            <td colSpan={5} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!logs || logs.length === 0) return (<tr>
            <td colSpan={5} className="text-center">
                No available data
            </td>
        </tr>)

        const formatAddress = (input: string) => {
            return (input !== "SERVER" && input !== "N/A") ? shortenAddress(input) : input;
        }

        return (<>{logs.map((log: LogData) =>
            <tr>
                <td className="w-20">{new Date(log.date).toLocaleString()}</td>
                <td className="text-center">{log.level}</td>
                <td className="text-center">{log.address ? formatAddress(log.address) : 'N/A'}</td>
                <td dangerouslySetInnerHTML={{ __html: messageFormatter(log.message) }}></td>
            </tr>)
        }</>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={4}>
                <h3>Application Logs</h3>
            </Col>
            <Col sm={2} className="text-end">
                <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <InputGroup.Text id="search-start">Start Date</InputGroup.Text>
                            <Form.Control
                                type="date"
                                onChange={updateStartDate}
                                defaultValue={startDate}
                                value={startDate}
                                placeholder="FkRn..."
                                aria-label="Address"
                                aria-describedby="search-address"
                                size="sm"
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            <InputGroup.Text id="search-end">End Date</InputGroup.Text>
                            <Form.Control
                                type="date"
                                onChange={updateEndDate}
                                defaultValue={endDate}
                                value={endDate}
                                placeholder="0"
                                aria-label="Address"
                                aria-describedby="search-address"
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            <InputGroup.Text id="search-address">Address</InputGroup.Text>
                            <Form.Control
                                onChange={updateSearchAddress}
                                defaultValue={searchAddress}
                                aria-label="Search Address"
                                aria-describedby="search-address"
                            />
                            <InputGroup.Text id="search-address-magnifier"><SlMagnifier /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            <InputGroup.Text id="search-message">Message</InputGroup.Text>
                            <Form.Control
                                onChange={updateSearchText}
                                defaultValue={searchText}
                                aria-label="Search Message"
                                aria-describedby="search-message"
                            />
                            <InputGroup.Text id="search-message-magnifier"><SlMagnifier /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Table className="fs-7">
            <thead>
                <tr>
                    <td>Date</td>
                    <td className="text-center">Type</td>
                    <td className="text-center">Address</td>
                    <td>Message</td>
                </tr>
            </thead>
            <tbody>
                <Logs />
            </tbody>
        </Table>
        <Row className="justify-content-end">
            <Col>
                <PaginationComponent total={totalLogs} count={count} updateData={handlePageChange} skip={skip} />
            </Col>
            <Col sm={6}>
                <Row>
                    <Col className="pt-2 text-end">
                        <i className="text-muted">
                            Total Records: {totalLogs}
                        </i>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-count">Count</InputGroup.Text>
                            <Form.Select
                                value={count}
                                onChange={updateCount}
                                aria-label="Count"
                                aria-describedby="search-count">
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default LogsPanel;