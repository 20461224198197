import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Rarity } from "@growerz/shared";

import Plant from "./Plant";
import useGrowhouse from "../../../hooks/useGrowhouse";

const Plants = () => {
    const { harvestCaps, plants } = useGrowhouse();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (!plants || loading || !harvestCaps) {
        return <div className="text-center">Loading plants...</div>;
    }

    if (plants.length === 0 && !loading) {
        return <div className="text-center">No plants available</div>;
    }

    return <Row className="plants-container g-0">
        {
            plants.map((plant) => {
                let disabled = false;

                switch (plant.strain.rarity.name) {
                    case Rarity.COMMON:
                        disabled = harvestCaps.common.plants;
                        break;
                    case Rarity.UNCOMMON:
                        disabled = harvestCaps.uncommon.plants;
                        break;
                    case Rarity.RARE:
                        disabled = harvestCaps.rare.plants;
                        break;
                    case Rarity.EPIC:
                        disabled = harvestCaps.epic.plants;
                        break;
                    case Rarity.MYTHIC:
                        disabled = harvestCaps.mythic.plants;
                        break;
                    default: break;
                }

                return <Plant plant={plant} key={plant.id} disabled={disabled} />
            })
        }
    </Row>
}

export default Plants;