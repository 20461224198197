import { Button, Col, Modal, Row } from "react-bootstrap";
import { formatCurrency } from "@growerz/shared";
import useGrowhouse from "../../../hooks/useGrowhouse";

const PlantHarvestModal = ({
    payout,
    maxHarvested,
    hide
}: {
    payout?: string,
    maxHarvested: boolean,
    hide: () => void
}) => {
    const { remainingTHC } = useGrowhouse();
    const showing = payout !== undefined;

    if (payout === '0' || Number(payout) === 0) {
        return <Modal variant="dark" show={showing} onHide={hide} size="lg" className="modal-bad text-white" centered>
            <Modal.Header className="bg-white">
                <h1 className="text-center ff-thumbs pt-1 text-dark">Unsuccessful Harvest</h1>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center">
                    <Col className="text-center" xs={12}>
                        <h4>Your plant was dead, so you did not earn a harvest.</h4>
                        <h2 className="py-2">
                            <span className="text-danger">0</span> THC
                        </h2>
                        <h6>Try watering your plant next time!</h6>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        <Button variant="thc" size={'lg'} className="text-white px-3" onClick={hide}>Continue</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    }

    return <Modal variant="dark" show={showing} onHide={hide} size="lg" className="modal-notice text-white" centered>
        <Modal.Header className="bg-thc pt-3 pb-2">
            <h1 className="text-center ff-thumbs text-white">Successful Harvest</h1>
        </Modal.Header>
        <Modal.Body>
            <Row className="justify-content-center mb-3">
                <Col className="text-center" xs={12} md={8}>
                    <h3>You have successfully harvested <span className="text-success">{formatCurrency(Number(payout), 0)}</span> THC!</h3>
                </Col>
            </Row>
            {maxHarvested && remainingTHC && (<>
                <hr className="my-0" />
                <Row className="justify-content-center pt-4 pb-4">
                    <Col className="text-center" xs={12} md={10}>
                        <h4>Congratulations!</h4>
                        {remainingTHC.payoutReceived < remainingTHC.upgradeCost && (
                            <>
                                <p>
                                    You have reached your harvesting upgrade requirements and have unlocked the
                                    full ability to plant seeds and harvest plants of any rarity until you reach
                                    your THC Harvest Limit!
                                </p>
                                <hr />
                                <div className="w-75 mx-auto">
                                    Harvest Limit:
                                    <h4>
                                        <span className="text-success">{remainingTHC.payoutReceived}</span> / {remainingTHC.upgradeCost}
                                    </h4>
                                </div>
                            </>
                        )}
                        {remainingTHC.payoutReceived > remainingTHC.upgradeCost && (
                            <>
                                <p>
                                    You have reached your harvesting upgrade requirements! However, you've gone over
                                    your allowed harvest limit and won't be able to plant seeds or harvest plants of
                                    any rarity until you upgrade your Growhouse.
                                </p>
                                <hr />
                                <div className="w-75 mx-auto">
                                    Harvest Limit:
                                    <h4>
                                        <span className="text-danger">{remainingTHC.payoutReceived}</span> / {remainingTHC.upgradeCost}
                                    </h4>
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </>)}
            <Row>
                <Col className="text-center">
                    <Button variant="thc" size={'lg'} className="text-white px-3" onClick={hide}>Continue</Button>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}

export default PlantHarvestModal;