import { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({
    total,
    count,
    skip,
    updateData
}: {
    total: number,
    count: number,
    skip: number,
    updateData: (currentPage: number) => void
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        updateData(currentPage);
    }, [currentPage, updateData]); // Update data whenever currentPage changes

    useEffect(() => {
        if (skip === 0) setCurrentPage(1);
    }, [skip]); // Reset the current page when the skip changes back to 0

    // Function to handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Calculate total number of pages
    const totalPages = Math.ceil(total / count);

    // Generate array of pages to display in pagination
    const generatePageNumbers = () => {
        const pages = [];
        let startPage = currentPage - 4;
        let endPage = currentPage + 4;

        if (startPage < 1) {
            endPage += Math.abs(startPage) + 1;
            startPage = 1;
        }

        if (endPage > totalPages) {
            endPage = totalPages;
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    return (<Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
            &laquo;
        </Pagination.First>
        {/* Previous button */}
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            &lt;
        </Pagination.Prev>

        {/* Page numbers */}
        {generatePageNumbers().map((page) => (
            <Pagination.Item key={page} onClick={() => handlePageChange(page)} className={currentPage === page ? 'active' : ''}>
                {page}
            </Pagination.Item>
        ))}

        {/* Next button */}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            &gt;
        </Pagination.Next>
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
            &raquo;
        </Pagination.Last>
    </Pagination>);
};

export default PaginationComponent;
