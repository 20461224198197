import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Button, ButtonGroup, Col, Modal, OverlayTrigger, Row, Table, ToggleButton, Tooltip } from "react-bootstrap";
import { Transaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { DispensarySeedData, NotificationType, PaymentMethod, calculateMaxSeeds, roundDecimal } from "@growerz/shared";

import { useWalletBalance } from "../../../hooks/useWalletBalance";
import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";

import DispensarySeed from "./DispensarySeed";
import useCart from "../../../hooks/useCart";
import { LoadingBackdrop } from "../../common/Loading";
import useGrowhouse from "../../../hooks/useGrowhouse";

const dispensaryImg = require('../../../assets/images/game/dispensary.png');
const tokenImg = require('../../../assets/images/token.png')
const solanaImg = require('../../../assets/images/solana.png');
const tokenImgBw = require('../../../assets/images/token-bw.png')
const solanaImgBw = require('../../../assets/images/solana-bw.png');

const DispensaryModal = () => {
    const { get, post } = useApi();
    const { growhouseId } = useParams();
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const { balance, tokenBalance } = useWalletBalance();
    const { addErrorNotification, addNotification } = useNotification();
    const { growhouse, seeds, refreshData } = useGrowhouse();

    const [showing, setShowing] = useState(false);
    const [purchasing, setPurchasing] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [maxSeeds, setMaxSeeds] = useState(0);

    const [dispensaryInventory, setDispensaryInventory] = useState<DispensarySeedData[]>();
    const { cart, total, paymentMethod, resetCart, togglePaymentMethod } = useCart();

    useEffect(() => {
        if (!dispensaryInventory) {
            get('/dispensary').then((response) => {
                if (response.success) {
                    setDispensaryInventory(response.data)
                }
            });
        }

        if (growhouse) {
            setMaxSeeds(calculateMaxSeeds(growhouse));
        }
    }, [dispensaryInventory, growhouse, get]);

    function show() {
        setShowing(true);
    }

    function hide() {
        resetCart();
        setShowing(false);
    }

    async function handlePurchase(event: any) {
        if (!sendTransaction) return;
        if (purchasing) return;

        if (seeds.length + cart.length > maxSeeds) {
            addErrorNotification("Purchase failed", "Unable to buy seed, maximum seeds reached!");
            return;
        }

        let balanceToCheck = paymentMethod === "thc" ? tokenBalance : balance;

        if (total > balanceToCheck) {
            addErrorNotification("Purchase failed", "Insufficient funds!");
            return;
        }

        if (publicKey) {
            setPurchasing(true);
            setLoadingMessage("Purchasing seeds...");

            let timer: NodeJS.Timeout | undefined;
            const startTimer = () => {
                timer = setTimeout(() => {
                    setLoadingMessage("Please be patient...");
                }, 5000);
            };

            const clearTimer = () => {
                if (timer) {
                    clearTimeout(timer);
                    timer = undefined;
                }
            };

            try {
                startTimer();

                const response = await post(`/dispensary/bulkbuy`, {
                    growhouseId,
                    cart,
                    paymentMethod
                });

                if (!response.success) throw new Error(response.message);

                const transaction = response.data;
                const transactionObj = Transaction.from(Buffer.from(transaction, 'base64'));
                const signature = await sendTransaction(transactionObj, connection);

                const completion = await post(`/dispensary/confirm`, {
                    growhouseId,
                    cart,
                    signature
                });

                if (!completion.success) throw new Error(completion.message);

                addNotification("Purchase successful", `You've received ${completion.data} ${completion.data > 1 ? 'seeds' : 'seed'}!`, NotificationType.Success);
                hide();
                refreshData();
            } catch (error: any) {
                if (error.name === "WalletSendTransactionError")
                    addErrorNotification("Action failed", "User rejected transaction!");
                else
                    addErrorNotification("Action failed", "Failed to purchase seed, contact support!");

                console.error("Failed to purchase seed: " + error.message);
            } finally {
                setPurchasing(false);
                setLoadingMessage(undefined);
                clearTimer();
            }
        }
    }

    const InventoryCount = () => {
        return <span>Growhouse Seeds: {seeds.length}/{maxSeeds}</span>
    }

    const DispensaryInventory = () => {
        if (!dispensaryInventory)
            return <tr><td colSpan={5}>Loading dispensary inventory...</td></tr>

        if (dispensaryInventory && dispensaryInventory.length === 0)
            return <tr><td colSpan={5}>No available seeds</td></tr>

        let isAtMaxSeeds = (cart.length === 10) || (cart.length + seeds.length >= maxSeeds);

        return <>
            {dispensaryInventory.map(seed =>
                <DispensarySeed seed={seed} key={seed.id} maxSeeds={isAtMaxSeeds} />
            )}
        </>
    }

    const CheckoutButton = () => {
        if (purchasing) {
            return <Button variant="warning text-light" className='mt-3 px-3' disabled={purchasing}>
                Wait
            </Button>
        }

        let balanceToCheck = paymentMethod === "thc" ? tokenBalance : balance;

        if (total > balanceToCheck || total <= 0) {
            return <Button variant="outline-success" className='mt-3 px-3' disabled={true}>
                Checkout
            </Button>
        }

        let disabled = (cart.length + seeds.length > maxSeeds) || purchasing;

        return <Button variant="thc" className="text-white mt-3 px-3" onClick={handlePurchase} disabled={disabled}>
            Checkout
        </Button>
    }

    return <>
        <LoadingBackdrop showing={purchasing} message={loadingMessage} />
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"lg"} enforceFocus={purchasing}>
            <Modal.Header closeButton closeVariant="white">
                <Row className="justify-content-between w-100">
                    <Col xs={4}>
                        THC Labz Seed Dispensary
                    </Col>
                    <Col className="px-auto text-center">
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'bottom'} overlay={<Tooltip>Payment Method (THC/SOL)</Tooltip>}>
                            <ButtonGroup className="switch-wrap">
                                <ToggleButton
                                    className="switch"
                                    key={201}
                                    id={`radio-${201}`}
                                    type="radio"
                                    variant={'link'}
                                    name="payment-value"
                                    value={"thc"}
                                    checked={paymentMethod === PaymentMethod.THC}
                                    onChange={togglePaymentMethod}
                                >
                                    <img src={paymentMethod === PaymentMethod.THC ? tokenImg : tokenImgBw} height={24} alt="THC Token" />
                                </ToggleButton>
                                <ToggleButton
                                    className="switch"
                                    key={202}
                                    id={`radio-${202}`}
                                    type="radio"
                                    variant={'link'}
                                    name="payment-value"
                                    value={"sol"}
                                    checked={paymentMethod === PaymentMethod.SOL}
                                    onChange={togglePaymentMethod}
                                >
                                    <img src={paymentMethod === PaymentMethod.SOL ? solanaImg : solanaImgBw} height={24} alt="Solana" />
                                </ToggleButton>
                            </ButtonGroup>
                        </OverlayTrigger>
                    </Col>
                    <Col className="text-end me-3">
                        <InventoryCount />
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <p className="fs-7">
                    Welcome to the THC Labz Dispensary! This is where you can purchase seeds with either THC or SOL.
                    Strains are randomly chosen based on the rarity of the seed.
                </p>
                <Row className="justify-content-center g-3">
                    <Table striped hover variant="dark" className="text-center">
                        <thead>
                            <tr>
                                <th className="bg-thc text-white" colSpan={2}>Rarity</th>
                                <th className="bg-thc text-white d-none d-md-table-cell">Strains</th>
                                <th className="bg-thc text-white">Cost</th>
                                <th className="bg-thc text-white">Cart</th>
                            </tr>
                        </thead>
                        <tbody>
                            <DispensaryInventory />
                        </tbody>
                    </Table>
                </Row>
                <p className="fs-7">
                    <i>
                        <strong>Note:</strong> You can only purchase up to 10 seeds at a time, if your
                        balance is greater than the cost of the seeds, and if you have enough space in
                        your Growhouse inventory.
                        <br />
                        <span className="text-warning">
                            Solana can sometimes take a while to confirm, so please be patient and do not
                            reload this page!
                        </span>
                    </i>
                </p>
                <hr />
                <Row className="justify-content-end">
                    <Col className="fs-7">
                        <i>
                            Cart Items: {cart.length} / 10
                        </i>
                    </Col>
                    <Col className="text-end">
                        {paymentMethod === "thc" && (<>
                            Total: {total} THC / {Math.floor(tokenBalance)} THC
                        </>)}
                        {paymentMethod === "sol" && (<>
                            Total: {total.toFixed(3)} SOL / {roundDecimal(balance, 3)} SOL
                        </>)}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <CheckoutButton />
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide} disabled={purchasing}>Close</Button>
            </Modal.Footer>
        </Modal>
        <button className="btn-dispensary text-center p-2 w-100 fs-7 fs-lg-6" onClick={show}>
            <img src={dispensaryImg} alt="Dispensary" height={64} />
            <br />
            Dispensary
        </button>
    </>
}

export default DispensaryModal;