import { useWallet } from "@solana/wallet-adapter-react";
import { useIdentity } from "../contexts/IdentityContext";

const DisconnectPage = () => {
    const { resetIdentity } = useIdentity();
    const { disconnect } = useWallet();

    disconnect();
    resetIdentity(true);

    return <></>;
}

export default DisconnectPage;