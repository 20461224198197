import b58 from 'bs58';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { CallbackResponse, Identity } from '@growerz/shared';

import { useApi } from './ApiContext';
import { getIdentity, resetStorageIdentity, setStorageIdentity } from '../services/IdentityService';
import AdminApiService from '../services/AdminApiService';

interface IdentityState {
    identity: Identity | undefined,
    authenticated: boolean,
    authenticating: boolean,
    authenticate: () => void,
    resetIdentity: (redirect: boolean) => void,
    adminAuthenticate: () => Promise<boolean>
}

export const IdentityContext = createContext<IdentityState>({
    identity: undefined,
    authenticated: false,
    authenticating: false,
    authenticate: () => { },
    resetIdentity: () => { },
    adminAuthenticate: async () => { return Promise.resolve(false); }
});

export const IdentityProvider = (props: { children?: React.ReactNode }) => {
    const { connected, connecting, disconnecting, publicKey, sendTransaction, signMessage } = useWallet();
    const { connection } = useConnection();
    const { post } = useApi();
    const navigate = useNavigate();
    const identityRef = useRef<Identity>();
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticating, setAuthenticating] = useState(false);
    const [userRejected, setUserRejected] = useState(false);

    const setIdentity = useCallback((identity: Identity) => {
        identityRef.current = identity;
        setStorageIdentity(identity);
        setAuthenticated(true);
        setAuthenticating(false);
    }, []);

    const resetIdentity = useCallback((redirect: boolean = true) => {
        identityRef.current = undefined;
        resetStorageIdentity();
        setAuthenticated(false);
        if (redirect) navigate('/');
    }, [navigate]);

    const authenticate = useCallback(async () => {
        if (authenticated || authenticating) return;
        if (!publicKey || !connected) return;
        if (!sendTransaction) return;

        setAuthenticating(true);
        setUserRejected(false);

        let data = {
            publicKey: publicKey.toBase58()
        };

        post('/authenticate', data)
            .then((response: CallbackResponse) => {
                if (!response.success) throw new Error(response.message);
                setIdentity(response.data);
                setUserRejected(false);
            }).catch((error: any) => {
                if (error.name === "WalletSendTransactionError")
                    setUserRejected(true);

                console.log(error.message);
            }).finally(() => {
                setAuthenticating(false);
            });
    }, [authenticated, authenticating, connected, publicKey, post, setIdentity, sendTransaction]);

    const adminAuthenticate = useCallback(async () => {
        if (authenticating || connecting) return false;
        if (!publicKey || !connected) return false;
        if (!signMessage) return false;

        setAuthenticating(true);
        setUserRejected(false);

        const message = publicKey.toBase58();
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await signMessage(encodedMessage);

        const data = {
            publicKey: b58.encode(publicKey.toBytes()),
            signedMessage: b58.encode(signedMessage),
            encodedMessage: b58.encode(encodedMessage)
        };

        try {
            let response = await AdminApiService.post('/authenticate', data)
            if (!response.success) throw new Error(response.message);
            setIdentity(response.data);
            setUserRejected(false);
            setAuthenticating(false);

            return true;
        } catch (error: any) {
            if (error.name === "WalletSignMessageError")
                setUserRejected(true);

            console.log(error.message);
            setAuthenticating(false);

            return false;
        }
    }, [authenticating, connected, connecting, publicKey, setIdentity, signMessage]);

    useEffect(() => {
        // If the user is not authenticated but connected to a wallet, we need them to authenticate
        if (!authenticated && !authenticating && connected && publicKey && !userRejected) {
            // Let's check if they have an identity first
            getIdentity().then((identity) => {
                // Do we have an identity in the local storage?
                if (!identity) {
                    // Nope, let's attempt to authenticate
                    authenticate();
                } else if (identity && identity.token) {
                    // We do and even have a token to prove it, but does the address match the public key?
                    if (identity.account.address !== publicKey.toBase58()) {
                        // Nope, reset it
                        resetIdentity();
                    } else {
                        // Yep, set the identity in the app
                        setIdentity(identity);
                    }
                }
            });
        }
    }, [authenticated, authenticating, connected, publicKey, userRejected, authenticate, setIdentity, resetIdentity]);

    useEffect(() => {
        // Check if the user is authenticated and connected
        if (authenticated && connected && publicKey && identityRef.current) {
            // We are connected, so let's throw a check in here
            connection.onAccountChange(
                publicKey,
                (updatedAccountInfo) => {
                    if (updatedAccountInfo.owner === publicKey) return;
                    resetIdentity(false);
                    authenticate();
                },
                'confirmed'
            )
        }
    }, [authenticated, connected, connection, publicKey, authenticate, resetIdentity]);

    // Disconnecting, reset everything
    useEffect(() => {
        if (disconnecting) {
            resetIdentity();
        }
    }, [disconnecting, resetIdentity]);

    const context = useMemo(() => ({
        identity: identityRef.current,
        authenticated,
        authenticating,
        authenticate,
        adminAuthenticate,
        resetIdentity
    }), [
        authenticated,
        authenticating,
        authenticate,
        adminAuthenticate,
        resetIdentity
    ]);

    return (
        <IdentityContext.Provider value={context}>
            {props.children}
        </IdentityContext.Provider>
    )
}

export const useIdentity = () => useContext(IdentityContext);