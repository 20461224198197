import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { StopwatchFill, DoorClosedFill } from "react-bootstrap-icons";
import { useWallet } from "@solana/wallet-adapter-react";
import { GrowhouseData, HubInfo } from "@growerz/shared";

import { useApi } from "../../contexts/ApiContext";
import { useIdentity } from "../../contexts/IdentityContext";
import { useNotification } from "../../contexts/NotificationContext";

import { LoadingBackdrop } from "../common/Loading";
import ScrollableContainer from "../common/ScrollableContainer";
import GrowhousePurchase from "./GrowhousePurchase";
import GrowhouseCard from "./growhouse/GrowhouseCard";

const plantIcon = require('../../assets/images/game/plants/icon.png');
const seedIcon = require('../../assets/images/game/seeds/common.png');
const waterIcon = require('../../assets/images/game/water.png');
const harvestIcon = require('../../assets/images/game/harvest.png');

const GrowhouseHub = () => {
    const navigate = useNavigate();
    const { get } = useApi();
    const { connected, connecting, disconnecting, publicKey } = useWallet();
    const { authenticated, authenticating } = useIdentity();
    const { addErrorNotification } = useNotification();

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string>();

    const [hubInfo, setHubInfo] = useState<HubInfo>();
    const [growhouses, setGrowhouses] = useState<GrowhouseData[]>();

    const refreshData = useCallback(() => {
        get('/account/hub').then((response) => {
            if (!response.success) {
                throw new Error(response.message);
            }

            setHubInfo(response.data.hub);
            setGrowhouses(response.data.growhouses);
        }).catch((error: any) => {
            if (error.message === 'Outdated client') {
                addErrorNotification("Failed to get hub data", `${error.message}, refresh to update`);
            } else {
                addErrorNotification("Failed to get hub data", "Contact support!");
            }
            console.log("Failed to get hub data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, get]);

    useEffect(() => {
        if (connecting || authenticating) return;
        if (!connected && !connecting && !authenticated && !authenticating) navigate('/');
        if (disconnecting) return;
        if (connected && authenticated && publicKey) {
            refreshData();
        }
    }, [connected, connecting, authenticated, authenticating, navigate, publicKey, refreshData, disconnecting]);

    useEffect(() => {
        if (disconnecting) return;
        if (loading) {
            if (connecting) {
                setLoadingMessage("Loading Growerz Hub...");
            } else {
                setLoadingMessage("Populating data...");
            }
        } else {
            setLoadingMessage(undefined);
        }
    }, [loading, connecting, disconnecting]);

    const Growhouses = () => {
        if (!growhouses || !hubInfo) return <Col className="text-center">
            <span>Loading growhouses...</span>
        </Col>

        if (growhouses && growhouses.length === 0) {
            return <Col className="text-center">
                No growhouses available
            </Col>
        }

        let mappedGrowhouses = growhouses.map((growhouse) => {
            return <Col key={growhouse.id} xs={12} sm={6} lg={4} className="item mb-3">
                <GrowhouseCard growhouse={growhouse} />
            </Col>;
        });

        let purchaseEnabled = growhouses.every(x => x.max_level === true);

        return <>
            <div className={`d-block d-md-none`}>
                {mappedGrowhouses}
                <Col key={"newGrowhouse"} xs={12} sm={6} lg={4} className={`item mb-3 ${purchaseEnabled ? 'd-block' : 'd-none'}`}>
                    <div className={`growhouse-card border-dashed`} style={{ height: 450 }}>
                        <Card className={`bg-black new h-100`}>
                            <Card.Body>
                                <GrowhousePurchase growhouses={growhouses} refresh={refreshData} />
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </div>
            <div className={`d-none d-md-block ${purchaseEnabled ? '' : 'justify-content-center text-center'}`}>
                <ScrollableContainer scrollEnabled={growhouses.length > 2 && purchaseEnabled}>
                    {mappedGrowhouses}
                    <Col key={"newGrowhouse"} xs={12} sm={6} lg={4} className={`item mb-3 ${purchaseEnabled ? '' : 'd-none'}`}>
                        <div className={`growhouse-card d-table`}>
                            <Card className={`bg-black new border-dashed`}>
                                <Card.Body>
                                    <GrowhousePurchase growhouses={growhouses} refresh={refreshData} />
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </ScrollableContainer>
            </div>
        </>
    }

    return <Container className="mt-5">
        <LoadingBackdrop message={loadingMessage} showing={loading} />
        <Row>
            <Col className="text-center">
                <h2>Welcome to your</h2>
                <h1>Growhouse Hub</h1>
            </Col>
        </Row>
        {growhouses && hubInfo && (<>
            <hr className="my-3" />
            <Row className={`hub justify-content-center`}>
                <Growhouses />
            </Row>
        </>)}
        <div className={`mt-3 border-plain d-none`}>
            <div className="fs-7 px-2 pt-2 pb-3">
                <h5>Legend</h5>
                <hr className="mt-0 mb-2" />
                <Row className={`text-center`}>
                    <Col className="pt-2">
                        <img src={plantIcon} alt="Plants" height={34} className="mb-2" />
                        <br />
                        Plants
                    </Col>
                    <Col className="pt-3">
                        <img src={seedIcon} alt="Seeds" height={18} className="mb-3" />
                        <br />
                        Seeds
                    </Col>
                    <Col className="pt-2 mt-1">
                        <img src={waterIcon} alt="Waterable" height={18} className="mb-3" />
                        <br />
                        Waterable
                    </Col>
                </Row>
                <Row className={`text-center`}>
                    <Col className="pt-2 mt-3">
                        <img src={harvestIcon} alt="Harvestable" height={18} className="mb-3" />
                        <br />
                        Harvestable
                    </Col>
                    <Col className="pt-2 mt-3">
                        <StopwatchFill className="text-warning fs-4 mb-3" />
                        <br />
                        Withered
                    </Col>
                    <Col className="pt-2 mt-3">
                        <DoorClosedFill className="text-secondary fs-4 mb-3" />
                        <br />
                        Dead
                    </Col>
                </Row>
            </div>
        </div>
    </Container>;
}

export default GrowhouseHub;