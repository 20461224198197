import { memo, useEffect, useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import Countdown from 'react-countdown';
import { VersionedTransaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { NotificationType, PlantAction, PlantData, PlantState, Rarity } from "@growerz/shared";
import { adjustClientDate, indexToRarity } from "@growerz/shared/util";

import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import SolanaService from "../../../services/SolanaService";
import useGrowhouse from "../../../hooks/useGrowhouse";
import { useWalletBalance } from "../../../hooks/useWalletBalance";

import { LoadingBackdrop } from "../../common/Loading";
import { PlantImages } from "../../common/PlantImages";
import PlantHarvestModal from "./PlantHarvestModal";

const Plant = ({
    plant,
    disabled
}: {
    plant: PlantData,
    disabled: boolean
}) => {
    const { get, post } = useApi();
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const { addErrorNotification, addNotification } = useNotification();
    const { tokenBalance } = useWalletBalance();
    const { checkPlantsStatus, refreshData, updateRemainingTHC } = useGrowhouse();

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [rarity, setRarity] = useState<Rarity>(Rarity.COMMON);

    const [pruning, setPruning] = useState(false);
    const [watering, setWatering] = useState(false);
    const [reviving, setReviving] = useState(false);
    const [harvesting, setHarvesting] = useState(false);
    const [harvestPayout, setHarvestPayout] = useState<string>();
    const [countdownCompleted, setCountdownCompleted] = useState(false);

    const [restricted, setRestricted] = useState(false);
    const [lastHarvest, setLastHarvest] = useState(false);

    useEffect(() => {
        if (plant.growhouse.max_level) {
            setRestricted(true);
        }

        setRarity(indexToRarity(plant.strain.rarity.id));
        setLoading(false);
    }, [plant]);

    useEffect(() => {
        const preloadImage = (imgSrc: string) => {
            const img = new Image();
            img.src = imgSrc;
        };

        Object.values(PlantImages).forEach((rarityImages) => {
            rarityImages.forEach((imgSrc) => {
                preloadImage(imgSrc);
            });
        });
    }, []);

    const handleCountdownCompleted = (event: any) => {
        if (countdownCompleted) return;
        setCountdownCompleted(true);
        checkPlantsStatus();
    }

    const handleWater = async (event: any) => {
        if (watering) return;
        const plantId = event.currentTarget.value;

        setWatering(true);
        setLoadingMessage('Watering plant...');

        const timer = setTimeout(() => {
            setLoadingMessage("Please be patient...");
        }, 5000);

        try {
            const currentTime = adjustClientDate(new Date());
            const response = await post(`/plant/${plantId}/${PlantAction.WATER}`, {
                currentTime
            });
            if (!response.success) throw new Error(response.message);

            addNotification("Action Successful", `Successfully watered plant!`, NotificationType.Success);
            refreshData();
        } catch (error: any) {
            addErrorNotification("Action Failed", `Unable to water plant, ${error.message}!`);
            console.error("Failed to water plant: " + error.message);
        } finally {
            setWatering(false);
            setLoadingMessage(undefined);
            clearTimeout(timer);
        }
    }

    const handleRevive = async (event: any) => {
        if (!sendTransaction) return;
        if (reviving) return;
        const plantId = event.currentTarget.value;

        if (publicKey) {
            setReviving(true);
            setLoadingMessage('Reviving plant...');

            let timer: NodeJS.Timeout | undefined;
            const startTimer = () => {
                timer = setTimeout(() => {
                    setLoadingMessage("Please be patient...");
                }, 5000);
            };

            const clearTimer = () => {
                if (timer) {
                    clearTimeout(timer);
                    timer = undefined;
                }
            };

            try {
                const { data: cost } = await get(`/plant/${plantId}/revivalcost`);
                if (cost === 0) throw new Error("Failed to get revival cost!");
                if (cost > tokenBalance) throw new Error("Insufficient THC balance!");

                const transaction = await SolanaService.createPlayerTransaction(publicKey, cost);
                const signature = await sendTransaction(transaction as VersionedTransaction, connection);

                startTimer();

                const response = await post(`/plant/${plantId}/revive`, {
                    transaction: signature,
                    cost,
                    growhouseId: plant.growhouse.id
                });

                if (!response.success) throw new Error(response.message);

                addNotification("Revival Successful", `Successfully revived plant!`, NotificationType.Success);
                refreshData();
            } catch (error: any) {
                if (error.name === "WalletSignMessageError")
                    addErrorNotification("Action failed", "User rejected transaction!");
                else
                    addErrorNotification("Action failed", `Failed to revive plant, ${error.message}`);

                console.error("Failed to revive plant: " + error.message);
            } finally {
                setReviving(false);
                setLoadingMessage(undefined);
                clearTimer();
            }
        }
    }

    const handlePrune = async (event: any) => {
        if (pruning) return;
        const plantId = event.currentTarget.value;

        try {
            setPruning(true);

            const response = await post(`/plant/${plantId}/${PlantAction.PRUNE}`);

            if (!response.success) {
                throw new Error(response.message);
            }

            addNotification("Action Successful", `Successfully pruned plant!`, NotificationType.Success);
            refreshData();
        } catch (error: any) {
            addErrorNotification("Action failed!", 'Unable to prune plant, contact support!');
            console.error("Failed to prune plant: " + error.message);
        } finally {
            setPruning(false);
        }
    }

    const handleHarvest = async (event: any) => {
        if (harvesting) return;

        setHarvesting(true);

        const timer = setTimeout(() => {
            setLoadingMessage("Please be patient...");
        }, 5000);

        try {
            const response = await post(`/plant/${plant.id}/${PlantAction.HARVEST}`);
            if (!response.success) throw new Error(response.message);

            setLastHarvest(response.data.lastHarvest);
            setHarvestPayout(response.data.payout);
            updateRemainingTHC(response.data.remainingTHC);
        } catch (error: any) {
            addErrorNotification("Action failed", `Unable to harvest plant, ${error.message}`);
            console.error("Failed to harvest plant: " + error.message);
        } finally {
            setHarvesting(false);
            setLoadingMessage(undefined);
            clearTimeout(timer);
        }
    }

    const resetHarvestPayout = () => {
        setHarvestPayout(undefined);
        refreshData();
    }

    const ActionButton = () => {
        const waterRenderer = ({ days, hours, minutes, seconds, completed }: { days: number, hours: number, minutes: number, seconds: number, completed: boolean }) => {
            let day = (days > 0) ? `${days}d ` : '';
            let hour = (hours > 0) ? `${hours}h ` : '';
            let minute = (minutes > 0) ? `${minutes}m ` : '';
            let second = (seconds > 0) ? `${seconds}s` : '';

            if (!completed) {
                if (plant.stage === 5)
                    return <Button variant="success" className='w-100 br-0 br-b-7 fs-6' size="lg" disabled>
                        Harvest in {day}{hour}{minute}{second}
                    </Button>
                else
                    return <Button variant="primary" className='w-100 br-0 br-b-7 fs-6' size="lg" disabled>
                        Water in {day}{hour}{minute}{second}
                    </Button>
            }

            if (plant.state === PlantState.DEAD) {
                return <Button variant="danger" className='w-100 br-0 br-b-7 fs-6' size="lg" onClick={handlePrune} value={plant.id} disabled={pruning}>
                    {harvesting ? 'Pruning..' : 'Prune (dead)'}
                </Button>
            }

            if (plant.state === PlantState.WITHERED) {
                return <Button variant="warning" className='w-100 br-0 br-b-7 fs-6 text-light' size="lg" onClick={handleRevive} value={plant.id} disabled={reviving}>
                    {harvesting ? 'Reviving...' : 'Revive (withered)'}
                </Button>
            }

            if (plant.state === PlantState.HARVESTABLE) {
                return <Button variant="success" className='w-100 br-0 br-b-7 fs-6' size="lg" onClick={handleHarvest} value={plant.id} disabled={harvesting || restricted || disabled}>
                    {harvesting ? 'Harvesting...' : 'Harvest'}
                </Button>
            } else {
                return <Button variant="primary" className='w-100 br-0 br-b-7 fs-6' size="lg" onClick={handleWater} value={plant.id} disabled={watering || loading}>
                    {watering ? 'Watering...' : 'Water'}
                </Button>
            }
        }

        if (plant.next_watering_time) {
            return <Countdown date={plant.next_watering_time} renderer={waterRenderer} onComplete={handleCountdownCompleted} />
        }

        return <></>
    }

    const PlantImage = memo(({ rarity, stage }: { rarity: Rarity, stage: number }) => {
        if (plant.state === PlantState.DEAD) return <img alt="Dead Plant" src={PlantImages[rarity][0]} />;
        if (plant.state === PlantState.WITHERED) {
            let img = PlantImages[rarity][stage + 5];
            return <img alt="Withered Plant" src={img} />;
        }

        let img = PlantImages[rarity][stage];
        return <img alt={`${rarity} Plant Stage`} src={img} />;
    });

    return <Col xs={12} sm={6} lg={3} xxxl={2} className="mb-4 px-2">
        <LoadingBackdrop message={loadingMessage} showing={harvesting || reviving} />
        <Card className={`plant-container w-100 bg-black border-${rarity.toLowerCase()}`}>
            <Card.Header className={`text-white bg-${rarity.toLowerCase()}`}>
                <Row className="justify-content-between p-relative">
                    <Col className="fs-6 ps-1">
                        {plant.strain.name}
                    </Col>
                    <Col className="text-end pe-1 fs-7" style={{ position: 'absolute', right: 0, top: 2 }}>
                        <span className={`font-thumbs-down rarity-text-${rarity.toLowerCase()}`}>
                            {rarity}
                        </span>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="text-center">
                <PlantImage rarity={rarity} stage={plant.stage} />
            </Card.Body>
            <Card.Footer className="p-0">
                <div className='w-100 br-0 br-b-7'>
                    <ActionButton />
                </div>
            </Card.Footer>
        </Card>
        <PlantHarvestModal payout={harvestPayout} hide={resetHarvestPayout} maxHarvested={lastHarvest} />
    </Col>
}

export default Plant;