import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { DoorClosedFill, StopwatchFill } from "react-bootstrap-icons";

import { GrowhouseData, PlantState, Rarity, indexToRarity } from "@growerz/shared";

const NFT_IMAGE_URL = process.env.REACT_APP_NFT_IMAGE_URL ?? '';

const growhouseImg = require('../../../assets/images/game/hub/growhouse-background.png');
const plantIcon = require('../../../assets/images/game/plants/icon.png');
const seedIcon = require('../../../assets/images/game/seeds/common.png');
const waterIcon = require('../../../assets/images/game/water.png');
const harvestIcon = require('../../../assets/images/game/harvest.png');

const GrowhouseCard = ({
    growhouse
}: {
    growhouse: GrowhouseData
}) => {
    const navigate = useNavigate();
    const [flipped, setFlipped] = useState(false);
    const [rarity, setRarity] = useState<Rarity>(Rarity.COMMON);

    const flip = () => setFlipped(!flipped);

    const calculateRarity = (growers: any) => {
        if (!growers || growers.length === 0) return Rarity.COMMON;

        let total = 0;
        growers.forEach((grower: any) => total += grower.class.id);
        let rarity = Math.round(total / growers.length);

        return indexToRarity(rarity);
    }

    useEffect(() => {
        setRarity(calculateRarity(growhouse.growers));
    }, [growhouse]);

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isRightSwipe) setFlipped(true);
        if (isLeftSwipe) setFlipped(false);
    }

    return <div className={`growhouse-card ${flipped ? 'flipped' : ''}`}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onClick={() => navigate(`/growhouse/${growhouse.id}`)}
    >
        <Card className={`bg-black border-${rarity.toLowerCase()} front`}>
            <Card.Header className={`text-white bg-${rarity.toLowerCase()}`}>
                <Row className="justify-content-between p-relative">
                    <Col>
                        <span className="fs-6">
                            {growhouse.nickname ?? 'Growhouse # ' + growhouse.id} - <i className="fs-7">Lv {growhouse.level}</i>
                        </span>
                    </Col>
                    <Col className="text-end" style={{ position: 'absolute', right: 0, width: '50%' }}>
                        <span className={`font-thumbs-down rarity-text-${rarity.toLowerCase()}`}>
                            {rarity}
                        </span>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="text-center">
                <Row className={`text-center mx-0 px-0`}>
                    <Col>
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'top'} overlay={<Tooltip>Plants</Tooltip>}>
                            <img src={plantIcon} alt="Plants" height={40} className="mb-2" />
                        </OverlayTrigger>
                        <br />
                        <span>{growhouse.plants?.length ?? 0}</span>
                    </Col>
                    <Col>
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'top'} overlay={<Tooltip>Seeds</Tooltip>}>
                            <img src={seedIcon} alt="Seeds" height={24} className="mt-2 mb-3" />
                        </OverlayTrigger>
                        <br />
                        <span>{growhouse.seeds?.length ?? 0}</span>
                    </Col>
                    <Col>
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'top'} overlay={<Tooltip>Waterable</Tooltip>}>
                            <img src={waterIcon} alt="Waterable" height={32} className="my-2" />
                        </OverlayTrigger>
                        <br />
                        <span>{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state !== PlantState.HARVESTABLE).length}</span>
                    </Col>
                    <Col>
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'top'} overlay={<Tooltip>Harvestable</Tooltip>}>
                            <img src={harvestIcon} alt="Harvestable" height={32} className="my-2" />
                        </OverlayTrigger>
                        <br />
                        <span>{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state === PlantState.HARVESTABLE).length}</span>
                    </Col>
                    <Col>
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'top'} overlay={<Tooltip>Withered</Tooltip>}>
                            <StopwatchFill className="text-warning fs-4 mt-3 mb-2" />
                        </OverlayTrigger>
                        <br />
                        <span>{growhouse.plants?.filter(x => x.state === PlantState.WITHERED).length}</span>
                    </Col>
                    <Col>
                        <OverlayTrigger trigger={["focus", "hover"]} placement={'top'} overlay={<Tooltip>Dead</Tooltip>}>
                            <DoorClosedFill className="text-secondary fs-4 mt-3 mb-2" />
                        </OverlayTrigger>
                        <br />
                        <span>{growhouse.plants?.filter(x => x.state === PlantState.DEAD).length}</span>
                    </Col>
                </Row>
                <hr />
                <img src={growhouseImg} alt="Growhouse" className="growhouse-img" />
                <hr />
                <Row className="text-center justify-content-center gx-2">
                    {growhouse.growers.length > 0 && growhouse.growers.map((grower) => {
                        return <Col xs={4} key={grower.id}>
                            <div className={`grower px-0 border-${grower.class.name.toLowerCase()} bg-${grower.class.name.toLowerCase()}`}>
                                <span className='fs-8'>{grower.class.name.toLowerCase()}</span>
                                <br />
                                <img
                                    alt={`Grower #${grower.id}`}
                                    src={NFT_IMAGE_URL + grower.id}
                                    key={grower.id}
                                    className="w-100" />
                                <br />
                                <span className="fs-8">Grower #{grower.id}</span>
                            </div>
                        </Col>
                    })}
                    {growhouse.growers.length === 0 && <Col className="text-center py-5">
                        <span>No Growers Assigned</span>
                    </Col>}
                </Row>
            </Card.Body>
            <Card.Footer className="p-0 d-none d-md-block">
                <Row className="g-0 w-100 br-0 br-b-7">
                    <Col>
                        <Button variant={'thc'} className="w-100 text-light br-0 br-bs-7" onClick={() => navigate(`/growhouse/${growhouse.id}`)}>Select</Button>
                    </Col>
                    <Col>
                        <Button variant={'secondary'} className="w-100 br-0 br-be-7" onClick={flip}>Details</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
        <Card className="bg-black border-thc back">
            <Card.Header className="text-center fs-5">
                Growhouse Statistics
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col className="text-start">
                        Level:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.level}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Growerz:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.growers.length}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Seeds:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.seeds?.length ?? 0}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Active Plants:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.plants?.filter(x => x.state > PlantState.SEED && x.state < PlantState.HARVESTED).length ?? 0}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Waterable Plants:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state !== PlantState.HARVESTABLE).length}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Harvestable Plants:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state === PlantState.HARVESTABLE).length}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Withered Plants:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.plants?.filter(x => x.state === PlantState.WITHERED).length}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-start">
                        Dead Plants:
                    </Col>
                    <Col xs={2} className="text-end">
                        {growhouse.plants?.filter(x => x.state === PlantState.DEAD).length}
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="p-0 d-none d-md-block">
                <Row className="g-0 w-100 br-0 br-b-7">
                    <Col>
                        <Button variant={'secondary'} className="w-100 br-0 br-b-7" onClick={flip}>return</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    </div>
}

export default GrowhouseCard;