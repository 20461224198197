import { useCallback, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { GrowerData, NotificationType } from "@growerz/shared";

import AdminApiService from "../../../services/AdminApiService";
import { useNotification } from "../../../contexts/NotificationContext";

const classes = [{
    id: 1,
    name: "Common"
}, {
    id: 2,
    name: "Uncommon"
}, {
    id: 3,
    name: "Rare"
}, {
    id: 4,
    name: "Epic"
}, {
    id: 5,
    name: "Mythic"
}];

const COLLECTION_URL = process.env.REACT_APP_NFT_IMAGE_URL ?? '';

const EditGrowerzModal = ({
    loading,
    grower,
    refreshData
}: {
    loading: boolean,
    grower: GrowerData,
    refreshData: () => void
}) => {
    const { addErrorNotification, addNotification } = useNotification();
    const [showing, setShowing] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const [rank, setRank] = useState<number | undefined>(grower.rank);
    const [rarity, setRarity] = useState<any>({
        id: grower.class.id,
        name: grower.class.name
    });
    const [growhouse, setGrowhouse] = useState<number | undefined>(grower.growhouseId);
    const [tokenAddress, setTokenAddress] = useState<string | undefined>(grower.token_address);
    const [owner, setOwner] = useState<string | undefined>(grower.owner);

    const handleSubmit = useCallback(() => {
        if (waiting) return;
        if (!rank || !rarity || !tokenAddress || !owner) return;

        setWaiting(true);
        AdminApiService.post(`/growerz/${grower.id}/update`, {
            rank,
            rarity: rarity.id,
            growhouse,
            token_address: tokenAddress,
            owner
        }).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification('Success', `Successfully modified Grower #${grower.id}`, NotificationType.Success);
            refreshData();
            hide();
        }).catch((error: any) => {
            addErrorNotification('Failed to edit grower', error.message);
        }).finally(() => {
            setWaiting(false);
        })
    }, [grower.id, waiting, rank, rarity, growhouse, tokenAddress, owner, addNotification, addErrorNotification, refreshData]);

    function updateClass(event: any) {
        let value = event.currentTarget.value;
        let rarity = classes.filter(c => c.id === Number(value))[0];
        if (!rarity) return;
        setRarity(rarity);
    }

    function updateGrowhouse(event: any) {
        let value = event.currentTarget.value;
        if (!value || value === 0) setGrowhouse(undefined);
        else setGrowhouse(value);
    }

    const hide = () => {
        setRank(undefined);
        setRarity(undefined);
        setGrowhouse(undefined);
        setTokenAddress(undefined);
        setOwner(undefined);
        setShowing(false);
    }

    const show = () => {
        setShowing(true);
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"lg"}>
            <Modal.Header closeButton closeVariant="white">
                <h4>
                    Edit Grower #{grower.id}
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={5} className="text-center p-4">
                        <img src={COLLECTION_URL + grower.id} alt="Grower" className="img-fluid" />
                    </Col>
                    <Col>
                        <Row>
                            <Col xs={3}>
                                <Form.Group className="mb-3" controlId="growerRank">
                                    <Form.Label>Rank</Form.Label>
                                    <Form.Control
                                        type="number"
                                        defaultValue={rank}
                                        onChange={(e) => setRank(Number(e.currentTarget.value))}
                                        className="bg-dark text-light"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="growerClass">
                                    <Form.Label>Class</Form.Label>
                                    <Form.Select
                                        onChange={updateClass}
                                        aria-label="Class"
                                        aria-describedby="grower-rarity"
                                        className="bg-dark text-light">
                                        {
                                            classes.map((c) => {
                                                if (!rarity) return <></>
                                                return (
                                                    <option value={c.id} selected={rarity.id === c.id}>{c.name}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group className="mb-3" controlId="growerRank">
                                    <Form.Label>Growhouse</Form.Label>
                                    <Form.Control
                                        type="number"
                                        defaultValue={growhouse}
                                        onChange={updateGrowhouse}
                                        className="bg-dark text-light"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="growerAddress">
                            <Form.Label>Grower Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={grower.address}
                                disabled
                                className="bg-dark text-light"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="tokenAddress">
                            <Form.Label>Associated Token Account</Form.Label>
                            <Form.Control
                                type="text"
                                value={tokenAddress}
                                onChange={(e) => setTokenAddress(e.target.value)}
                                className="bg-dark text-light"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ownerAddress">
                            <Form.Label>Owner Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={owner}
                                onChange={(e) => setOwner(e.target.value)}
                                className="bg-dark text-light"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="text-white mt-3 px-3" onClick={handleSubmit} disabled={waiting}>Submit</Button>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Button variant={"success"} className="text-white" onClick={show} disabled={loading} size="sm">Edit</Button>
    </>;
}

export default EditGrowerzModal;