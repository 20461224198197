import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useWallet } from "@solana/wallet-adapter-react";
import { GrowerData } from "@growerz/shared";

import GrowerLicense from "./GrowerLicense";
import MobileGrowerLicense from "./MobileGrowerLicense";
import { useApi } from "../../../contexts/ApiContext";

const GrowerzModal = ({
    oldGrowerMint,
    showing,
    hide,
    mobile
}: {
    oldGrowerMint?: string,
    showing: boolean,
    hide: () => void,
    mobile?: boolean
}) => {
    const { publicKey } = useWallet();
    const { get } = useApi();
    const [growerz, setGrowerz] = useState<GrowerData[]>();
    const [swappingGrower, setSwappingGrower] = useState<GrowerData>();
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('id');
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        if (!publicKey || !showing) return;
        get(`/growerz/${publicKey.toBase58()}`).then((response) => {
            if (!response.success) throw new Error("Failed to fetch Growerz");
            let growers = response.data.data;
            if (!growers) setGrowerz([]);
            else if (oldGrowerMint) {
                let oldGrower = growers.filter((x: GrowerData) => x.address === oldGrowerMint)[0];
                if (oldGrower) setSwappingGrower(oldGrower);
                growers = growers.filter((x: GrowerData) => x.address !== oldGrowerMint && !x.growhouse);
            }
            setGrowerz(growers);
        }).catch(() => {
            setGrowerz([]);
        })
    }, [publicKey, oldGrowerMint, showing, get]);

    const Growerz = () => {
        if (!growerz) return <Col className="text-center">Loading Growerz...</Col>

        let sortedGrowerz = growerz;

        if (filter !== 'all') {
            sortedGrowerz = growerz.filter(x => x.class.name === filter);
        }

        if (sort === 'id') {
            sortedGrowerz = sortedGrowerz.sort((a, b) => a.id - b.id);
        } else if (sort === 'rank') {
            sortedGrowerz = sortedGrowerz.sort((a, b) => a.rank - b.rank);
        } else if (sort === 'rarity') {
            sortedGrowerz = sortedGrowerz.sort((a, b) => a.class.id - b.class.id);
        }

        if (sortDirection === 'desc') sortedGrowerz = sortedGrowerz.reverse();

        if (sortedGrowerz.length === 0) return <Col className="text-center">No Growerz available</Col>

        return <>
            {
                sortedGrowerz.map((grower) => {
                    let disabled = (swappingGrower) ? grower.class.id < swappingGrower.class.id : false;
                    if (mobile) return <Col key={grower.id} xs={12} md={5}>
                        <MobileGrowerLicense grower={grower} hide={hide} show={() => { }} inList={false} swappingMint={oldGrowerMint} disabled={disabled} />
                    </Col>
                    return <Col key={grower.id} xs={12} md={5}>
                        <GrowerLicense grower={grower} hide={hide} show={() => { }} inList={false} swappingMint={oldGrowerMint} />
                    </Col>
                })
            }
        </>
    }

    return <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"xl"}>
        <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
                Your THC Labz Growerz
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="fs-7">
                Each class of Grower brings different bonuses to your Growhouse. You can add one Grower
                at the start, or swap one for another with the same or higher class. At level 3, you unlock
                a second Grower slot for your Growhouse and at level 7 you unlock a third slot.
            </p>
            <hr />
            <Row className="justify-content-end mb-3">
                <Col xs={4} lg={2}>
                    <Form.Select onChange={(e) => setSortDirection(e.currentTarget.value)} className="bg-dark text-light">
                        <option value='asc'>Ascending</option>
                        <option value='desc'>Descending</option>
                    </Form.Select>
                </Col>
                <Col xs={4} lg={2}>
                    <Form.Select onChange={(e) => setSort(e.currentTarget.value)} className="bg-dark text-light">
                        <option value='id'>ID</option>
                        <option value='rank'>Rank</option>
                        <option value='rarity'>Rarity</option>
                    </Form.Select>
                </Col>
                <Col xs={4} lg={2}>
                    <Form.Select onChange={(e) => setFilter(e.currentTarget.value)} className="bg-dark text-light">
                        <option value={'all'}>All</option>
                        <option>Common</option>
                        <option>Uncommon</option>
                        <option>Rare</option>
                        <option>Epic</option>
                        <option>Mythic</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="justify-content-center g-3">
                <Growerz />
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
        </Modal.Footer>
    </Modal>
}

export default GrowerzModal;