"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sleep = exports.formatCurrency = exports.adjustClientDate = exports.roundDecimal = exports.calculateHarvestPayout = exports.calculateHarvestBonus = exports.calculateWateringTime = exports.calculateMaxPlants = exports.calculateMaxSeeds = exports.validationStrictnessToIndex = exports.validationIndexToStrictness = exports.plantStateToString = exports.plantStateToIndex = exports.indexToPlantState = exports.indexToRarity = exports.rarityToIndex = exports.shortenAddress = void 0;
const enum_1 = require("../common/enum");
const constants_1 = require("../common/constants");
const skews_1 = require("../common/skews");
function shortenAddress(address, startLength, endLength) {
    if (!startLength)
        startLength = 4;
    if (!endLength)
        endLength = 4;
    return address.substring(0, startLength) + '..' + address.substring(address.length - endLength, address.length);
}
exports.shortenAddress = shortenAddress;
function rarityToIndex(rarity) {
    switch (rarity) {
        case enum_1.Rarity.MYTHIC:
            return 5;
        case enum_1.Rarity.EPIC:
            return 4;
        case enum_1.Rarity.RARE:
            return 3;
        case enum_1.Rarity.UNCOMMON:
            return 2;
        case enum_1.Rarity.COMMON:
        default:
            return 1;
    }
}
exports.rarityToIndex = rarityToIndex;
function indexToRarity(index) {
    switch (index) {
        case 5:
            return enum_1.Rarity.MYTHIC;
        case 4:
            return enum_1.Rarity.EPIC;
        case 3:
            return enum_1.Rarity.RARE;
        case 2:
            return enum_1.Rarity.UNCOMMON;
        case 1:
        default:
            return enum_1.Rarity.COMMON;
    }
}
exports.indexToRarity = indexToRarity;
function indexToPlantState(index) {
    switch (index) {
        case 4:
            return enum_1.PlantState.HARVESTED;
        case 3:
            return enum_1.PlantState.HARVESTABLE;
        case 2:
            return enum_1.PlantState.PLANT;
        case 1:
            return enum_1.PlantState.SAPLING;
        case -1:
            return enum_1.PlantState.WITHERED;
        case -2:
            return enum_1.PlantState.DEAD;
        case 0:
        default:
            return enum_1.PlantState.SEED;
    }
}
exports.indexToPlantState = indexToPlantState;
function plantStateToIndex(state) {
    switch (state) {
        case enum_1.PlantState.HARVESTED:
            return 4;
        case enum_1.PlantState.HARVESTABLE:
            return 3;
        case enum_1.PlantState.PLANT:
            return 2;
        case enum_1.PlantState.SAPLING:
            return 1;
        case enum_1.PlantState.WITHERED:
            return -1;
        case enum_1.PlantState.DEAD:
            return -2;
        case enum_1.PlantState.SEED:
        default:
            return 0;
    }
}
exports.plantStateToIndex = plantStateToIndex;
function plantStateToString(state) {
    switch (state) {
        case enum_1.PlantState.HARVESTED:
            return "Harvested";
        case enum_1.PlantState.HARVESTABLE:
            return "Harvestable";
        case enum_1.PlantState.PLANT:
            return "Plant";
        case enum_1.PlantState.SAPLING:
            return "Sapling";
        case enum_1.PlantState.WITHERED:
            return "Withered";
        case enum_1.PlantState.DEAD:
            return "Dead";
        case enum_1.PlantState.SEED:
        default:
            return "Seed";
    }
}
exports.plantStateToString = plantStateToString;
function validationIndexToStrictness(strictness) {
    let index = Number(strictness);
    switch (index) {
        case 2:
            return enum_1.ValidationStrictness.FINALIZED;
        case 1:
            return enum_1.ValidationStrictness.CONFIRMED;
        case 0:
        default:
            return enum_1.ValidationStrictness.PENDING;
    }
}
exports.validationIndexToStrictness = validationIndexToStrictness;
function validationStrictnessToIndex(strictness) {
    switch (strictness) {
        case enum_1.ValidationStrictness.FINALIZED:
            return 2;
        case enum_1.ValidationStrictness.CONFIRMED:
            return 1;
        case enum_1.ValidationStrictness.PENDING:
        default:
            return 0;
    }
}
exports.validationStrictnessToIndex = validationStrictnessToIndex;
function calculateMaxSeeds(growhouse) {
    var _a, _b;
    let growerBonus = 0;
    if (growhouse.growers && growhouse.growers.length > 0) {
        growhouse.growers.forEach((grower) => {
            growerBonus += grower.class.inventory_boost;
        });
    }
    let growhouseMaxSeeds = (_b = (_a = growhouse.base) === null || _a === void 0 ? void 0 : _a.maxSeeds) !== null && _b !== void 0 ? _b : 5;
    return growhouseMaxSeeds + growerBonus;
}
exports.calculateMaxSeeds = calculateMaxSeeds;
function calculateMaxPlants(growhouse) {
    var _a, _b;
    let growerBonus = 0;
    if (growhouse.growers && growhouse.growers.length > 0) {
        growhouse.growers.forEach((grower) => {
            growerBonus += grower.class.plant_boost;
        });
    }
    let growhouseMaxPlants = (_b = (_a = growhouse.base) === null || _a === void 0 ? void 0 : _a.maxPlants) !== null && _b !== void 0 ? _b : 3;
    return growhouseMaxPlants + growerBonus;
}
exports.calculateMaxPlants = calculateMaxPlants;
function calculateWateringTime(growhouse, plantRarity) {
    let base_water_time = Number(growhouse.base.wateringTime);
    let nerf = 1;
    if (growhouse.growers.length === 2)
        nerf = 0.8;
    if (growhouse.growers.length === 3)
        nerf = 0.8;
    let growerBonus = growhouse.growers.reduce((prev, grower) => prev + (Number(grower.class.harvest_boost) + (0, skews_1.getSkew)(grower.class.name).grower), 0) * nerf;
    let plantBonus = (plantRarity && plantRarity.harvest_boost) ? Number(plantRarity.harvest_boost) + (0, skews_1.getSkew)(plantRarity.name).plant : 0;
    let totalTime = roundDecimal(base_water_time - growerBonus - plantBonus, 2);
    return Number(totalTime);
}
exports.calculateWateringTime = calculateWateringTime;
function calculateHarvestBonus(growhouse, plantRarity) {
    let base_harvest_bonus = Number(growhouse.base.harvestBonus);
    let nerf = 1;
    if (growhouse.growers.length === 2)
        nerf = 0.5;
    if (growhouse.growers.length === 3)
        nerf = 0.35;
    let growerBonus = growhouse.growers.reduce((prev, grower) => prev + (Number(grower.class.harvest_boost) + (0, skews_1.getSkew)(grower.class.name).grower), 0) * nerf;
    let plantBonus = (plantRarity && plantRarity.harvest_boost) ? Number(plantRarity.harvest_boost) + (0, skews_1.getSkew)(plantRarity.name).plant : 0;
    let bonus = base_harvest_bonus + growerBonus + plantBonus;
    return bonus;
}
exports.calculateHarvestBonus = calculateHarvestBonus;
function calculateHarvestPayout(growhouse, plant) {
    let harvest_factor = calculateHarvestBonus(growhouse, plant.strain.rarity);
    let time_factor = calculateWateringTime(growhouse, plant.strain.rarity);
    let nerf = 1;
    if (growhouse.growers.length === 2)
        nerf = 0.5;
    if (growhouse.growers.length === 3)
        nerf = 0.35;
    let growerz = growhouse.growers.reduce((prev, grower) => prev + grower.class.id, 0) * nerf;
    let timeSpent = (growhouse.level + plant.strain.rarity.id + growerz) / (time_factor / 24) * 5;
    let payout = constants_1.BASE_THC_PAYOUT * timeSpent * harvest_factor;
    return Math.round(payout);
}
exports.calculateHarvestPayout = calculateHarvestPayout;
/*export function calculateWateringTime(growhouse: GrowhouseData, plantRarity?: RarityData) {
    let growhouseWateringTime = Number(growhouse.base!.wateringTime);

    let growerNerf = 1;
    if (growhouse.growers.length === 2) growerNerf = 0.8;
    if (growhouse.growers.length === 3) growerNerf = 0.8;

    let growerRarityBonus = growhouse.growers.reduce((prevValue, grower) => prevValue + Number(grower.class.water_boost), 0) * growerNerf;
    let plantRarityBonus = Number((plantRarity && plantRarity.water_boost) ? plantRarity.water_boost : 0);

    let hours = growhouseWateringTime - growerRarityBonus - plantRarityBonus;
    return ((hours * growhouse.growers.length) * growerNerf).toFixed(2);
}

export function calculateHarvestBonus(growhouse: GrowhouseData, plantRarity?: RarityData) {
    let growhouseHarvestBase = Number(growhouse.base!.harvestBonus);

    let growerNerf = 1;
    if (growhouse.growers.length === 2) growerNerf = 0.5;
    if (growhouse.growers.length === 3) growerNerf = 0.35;

    let growerRarityBonus = growhouse.growers.reduce((prevValue, grower) => prevValue + Number(grower.class.harvest_boost), 0) * growerNerf;
    let plantRarityBonus = Number((plantRarity && plantRarity.harvest_boost) ? plantRarity.harvest_boost : 0);

    let bonus = growhouseHarvestBase + growerRarityBonus + plantRarityBonus;
    return ((bonus * growhouse.growers.length) * growerNerf).toFixed(2);
}

export function calculateHarvestPayout(growhouse: GrowhouseData, plant: PlantData) {
    let harvestBonus = parseFloat(calculateHarvestBonus(growhouse, plant.strain.rarity));
    let hours = parseFloat(calculateWateringTime(growhouse, plant.strain.rarity));

    let growerNerf = 1;
    if (growhouse.growers.length === 2) growerNerf = 0.5;
    if (growhouse.growers.length === 3) growerNerf = 0.35;

    let growerRarities = growhouse.growers.reduce((prevValue, grower) => prevValue + grower.class.id, 0) * growerNerf;
    let timeSpent = parseFloat(roundDecimal((growhouse.level + plant.strain.rarity.id + growerRarities) / (hours / 24) * 5, 2));
    let totalPayout = BASE_THC_PAYOUT * timeSpent * harvestBonus;

    return Math.round(totalPayout);
}*/
function roundDecimal(num, maxDecimalPlaces = 4) {
    if (!num)
        num = 0;
    const roundedNumber = num.toFixed(maxDecimalPlaces);
    let decimalPlaces = maxDecimalPlaces;
    while (decimalPlaces > 0 && roundedNumber.endsWith('0')) {
        decimalPlaces--;
    }
    return parseFloat(roundedNumber).toFixed(decimalPlaces);
}
exports.roundDecimal = roundDecimal;
function adjustClientDate(localDate) {
    return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getHours(), localDate.getMinutes(), localDate.getSeconds()));
}
exports.adjustClientDate = adjustClientDate;
const formatCurrency = (num, min = 2, max = 2) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: min,
        maximumFractionDigits: max
    });
    return formatter.format(num);
};
exports.formatCurrency = formatCurrency;
const sleep = (ms) => __awaiter(void 0, void 0, void 0, function* () {
    yield new Promise(resolve => setTimeout(resolve, ms));
});
exports.sleep = sleep;
