const commonStageDead = require("../../assets/images/game/plants/common/dead.png");
const commonStage1 = require("../../assets/images/game/plants/common/stage1.png");
const commonStage2 = require("../../assets/images/game/plants/common/stage2.png");
const commonStage3 = require("../../assets/images/game/plants/common/stage3.png");
const commonStage4 = require("../../assets/images/game/plants/common/stage4.png");
const commonStage5 = require("../../assets/images/game/plants/common/stage5.png");
const commonWitheredStage1 = require("../../assets/images/game/plants/common/withered/stage1.png");
const commonWitheredStage2 = require("../../assets/images/game/plants/common/withered/stage2.png");
const commonWitheredStage3 = require("../../assets/images/game/plants/common/withered/stage3.png");
const commonWitheredStage4 = require("../../assets/images/game/plants/common/withered/stage4.png");
const commonWitheredStage5 = require("../../assets/images/game/plants/common/withered/stage5.png");
const uncommonStageDead = require("../../assets/images/game/plants/uncommon/dead.png");
const uncommonStage1 = require("../../assets/images/game/plants/uncommon/stage1.png");
const uncommonStage2 = require("../../assets/images/game/plants/uncommon/stage2.png");
const uncommonStage3 = require("../../assets/images/game/plants/uncommon/stage3.png");
const uncommonStage4 = require("../../assets/images/game/plants/uncommon/stage4.png");
const uncommonStage5 = require("../../assets/images/game/plants/uncommon/stage5.png");
const uncommonWitheredStage1 = require("../../assets/images/game/plants/uncommon/withered/stage1.png");
const uncommonWitheredStage2 = require("../../assets/images/game/plants/uncommon/withered/stage2.png");
const uncommonWitheredStage3 = require("../../assets/images/game/plants/uncommon/withered/stage3.png");
const uncommonWitheredStage4 = require("../../assets/images/game/plants/uncommon/withered/stage4.png");
const uncommonWitheredStage5 = require("../../assets/images/game/plants/uncommon/withered/stage5.png");
const rareStageDead = require("../../assets/images/game/plants/rare/dead.png");
const rareStage1 = require("../../assets/images/game/plants/rare/stage1.png");
const rareStage2 = require("../../assets/images/game/plants/rare/stage2.png");
const rareStage3 = require("../../assets/images/game/plants/rare/stage3.png");
const rareStage4 = require("../../assets/images/game/plants/rare/stage4.png");
const rareStage5 = require("../../assets/images/game/plants/rare/stage5.png");
const rareWitheredStage1 = require("../../assets/images/game/plants/rare/withered/stage1.png");
const rareWitheredStage2 = require("../../assets/images/game/plants/rare/withered/stage2.png");
const rareWitheredStage3 = require("../../assets/images/game/plants/rare/withered/stage3.png");
const rareWitheredStage4 = require("../../assets/images/game/plants/rare/withered/stage4.png");
const rareWitheredStage5 = require("../../assets/images/game/plants/rare/withered/stage5.png");
const epicStageDead = require("../../assets/images/game/plants/epic/dead.png");
const epicStage1 = require("../../assets/images/game/plants/epic/stage1.png");
const epicStage2 = require("../../assets/images/game/plants/epic/stage2.png");
const epicStage3 = require("../../assets/images/game/plants/epic/stage3.png");
const epicStage4 = require("../../assets/images/game/plants/epic/stage4.png");
const epicStage5 = require("../../assets/images/game/plants/epic/stage5.png");
const epicWitheredStage1 = require("../../assets/images/game/plants/epic/withered/stage1.png");
const epicWitheredStage2 = require("../../assets/images/game/plants/epic/withered/stage2.png");
const epicWitheredStage3 = require("../../assets/images/game/plants/epic/withered/stage3.png");
const epicWitheredStage4 = require("../../assets/images/game/plants/epic/withered/stage4.png");
const epicWitheredStage5 = require("../../assets/images/game/plants/epic/withered/stage5.png");
const mythicStageDead = require("../../assets/images/game/plants/mythic/dead.png");
const mythicStage1 = require("../../assets/images/game/plants/mythic/stage1.png");
const mythicStage2 = require("../../assets/images/game/plants/mythic/stage2.png");
const mythicStage3 = require("../../assets/images/game/plants/mythic/stage3.png");
const mythicStage4 = require("../../assets/images/game/plants/mythic/stage4.png");
const mythicStage5 = require("../../assets/images/game/plants/mythic/stage5.png");
const mythicWitheredStage1 = require("../../assets/images/game/plants/mythic/withered/stage1.png");
const mythicWitheredStage2 = require("../../assets/images/game/plants/mythic/withered/stage2.png");
const mythicWitheredStage3 = require("../../assets/images/game/plants/mythic/withered/stage3.png");
const mythicWitheredStage4 = require("../../assets/images/game/plants/mythic/withered/stage4.png");
const mythicWitheredStage5 = require("../../assets/images/game/plants/mythic/withered/stage5.png");

const PlantImagePreload = [
    commonStageDead,
    commonStage1,
    commonStage2,
    commonStage3,
    commonStage4,
    commonStage5,
    commonWitheredStage1,
    commonWitheredStage2,
    commonWitheredStage3,
    commonWitheredStage4,
    commonWitheredStage5,
    uncommonStageDead,
    uncommonStage1,
    uncommonStage2,
    uncommonStage3,
    uncommonStage4,
    uncommonStage5,
    uncommonWitheredStage1,
    uncommonWitheredStage2,
    uncommonWitheredStage3,
    uncommonWitheredStage4,
    uncommonWitheredStage5,
    rareStageDead,
    rareStage1,
    rareStage2,
    rareStage3,
    rareStage4,
    rareStage5,
    rareWitheredStage1,
    rareWitheredStage2,
    rareWitheredStage3,
    rareWitheredStage4,
    rareWitheredStage5,
    epicStageDead,
    epicStage1,
    epicStage2,
    epicStage3,
    epicStage4,
    epicStage5,
    epicWitheredStage1,
    epicWitheredStage2,
    epicWitheredStage3,
    epicWitheredStage4,
    epicWitheredStage5,
    mythicStageDead,
    mythicStage1,
    mythicStage2,
    mythicStage3,
    mythicStage4,
    mythicStage5,
    mythicWitheredStage1,
    mythicWitheredStage2,
    mythicWitheredStage3,
    mythicWitheredStage4,
    mythicWitheredStage5
]

export default PlantImagePreload;

export const PlantImages: {
    Common: string[],
    Uncommon: string[],
    Rare: string[],
    Epic: string[],
    Mythic: string[]
} = {
    "Common": [commonStageDead, commonStage1, commonStage2, commonStage3, commonStage4, commonStage5, commonWitheredStage1, commonWitheredStage2, commonWitheredStage3, commonWitheredStage4, commonWitheredStage5],
    "Uncommon": [uncommonStageDead, uncommonStage1, uncommonStage2, uncommonStage3, uncommonStage4, uncommonStage5, uncommonWitheredStage1, uncommonWitheredStage2, uncommonWitheredStage3, uncommonWitheredStage4, uncommonWitheredStage5],
    "Rare": [rareStageDead, rareStage1, rareStage2, rareStage3, rareStage4, rareStage5, rareWitheredStage1, rareWitheredStage2, rareWitheredStage3, rareWitheredStage4, rareWitheredStage5],
    "Epic": [epicStageDead, epicStage1, epicStage2, epicStage3, epicStage4, epicStage5, epicWitheredStage1, epicWitheredStage2, epicWitheredStage3, epicWitheredStage4, epicWitheredStage5],
    "Mythic": [mythicStageDead, mythicStage1, mythicStage2, mythicStage3, mythicStage4, mythicStage5, mythicWitheredStage1, mythicWitheredStage2, mythicWitheredStage3, mythicWitheredStage4, mythicWitheredStage5],
}