import { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { GrowhouseData, PlantState, shortenAddress } from "@growerz/shared";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";
import PaginationComponent from "../common/Pagination";
import AddPlantModal from "./modals/AddPlant";

const GrowhousesPanel = () => {
    const { addErrorNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [growhouses, setGrowhouses] = useState<GrowhouseData[]>();
    const [totalGrowhouses, setTotalGrowhouses] = useState<number>(0);

    const [searchAddress, setSearchAddress] = useState();
    const [searchGrowhouse, setSearchGrowhouse] = useState();
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(25);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.post('/growhouses/', {
            growhouse: Number(searchGrowhouse),
            address: searchAddress,
            skip,
            count
        }).then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.total !== totalGrowhouses) setTotalGrowhouses(response.data.total);
            setGrowhouses(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get plant data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, searchAddress, searchGrowhouse, skip, count, totalGrowhouses]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const updateSearchAddress = (event: any) => {
        setSearchAddress(event.currentTarget.value);
        setSkip(0);
    }

    const updateSearchGrowhouse = (event: any) => {
        setSearchGrowhouse(event.currentTarget.value);
        setSkip(0);
    }

    const updateCount = (event: any) => {
        setCount(event.currentTarget.value);
        setSkip(0);
    }

    const handlePageChange = (page: number) => {
        setSkip((page * count) - count);
    };

    const Growhouses = () => {
        if (loading) return (<tr>
            <td colSpan={9} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!growhouses || growhouses.length === 0) return (<tr>
            <td colSpan={9} className="text-center">
                No available data
            </td>
        </tr>)

        return (<>
            {growhouses.map((growhouse: GrowhouseData) =>
                <tr>
                    <td>{growhouse.id}</td>
                    <td>{shortenAddress(growhouse.account.address)}</td>
                    <td>{growhouse.level}</td>
                    <td>{growhouse.plants?.filter(x => x.stage > PlantState.SEED && x.stage < PlantState.HARVESTED).length ?? 0}</td>
                    <td>{growhouse.growers.length}</td>
                    <td>{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state === PlantState.PLANT).length}</td>
                    <td>{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state === PlantState.HARVESTABLE).length}</td>
                    <td>{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state === PlantState.WITHERED).length}</td>
                    <td>{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state === PlantState.DEAD).length}</td>
                </tr>
            )}
        </>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={6}>
                <h3>Growhouse Management</h3>
            </Col>
            <Col sm={6} className="text-end">
                <Row className="justify-content-end g-3">
                    <Col sm={2}>
                        <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
                    </Col>
                    <Col sm={3}>
                        <AddPlantModal loading={loading} refreshData={refreshData} />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="mb-3 justify-content-end">
            <Col sm={4}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-address">Address</InputGroup.Text>
                    <Form.Control
                        onChange={updateSearchAddress}
                        defaultValue={searchAddress}
                        placeholder="FkRn..."
                        aria-label="Address"
                        aria-describedby="search-address"
                        size="sm"
                    />
                </InputGroup>
            </Col>
            <Col sm={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-growhouse">Growhouse</InputGroup.Text>
                    <Form.Control
                        min="1"
                        type="number"
                        onChange={updateSearchGrowhouse}
                        defaultValue={searchGrowhouse}
                        placeholder="0"
                        aria-label="Address"
                        aria-describedby="search-address"
                    />
                </InputGroup>
            </Col>
        </Row>
        <Table className="fs-7">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Owner</th>
                    <th>Level</th>
                    <th>Plants</th>
                    <th>Growerz</th>
                    <th>Waterable Plants</th>
                    <th>Harvestable Plants</th>
                    <th>Dying Plants</th>
                    <th>Dead Plants</th>
                </tr>
            </thead>
            <tbody>
                <Growhouses />
            </tbody>
        </Table>
        <Row className="justify-content-end">
            <Col>
                <PaginationComponent total={totalGrowhouses} count={count} updateData={handlePageChange} skip={skip} />
            </Col>
            <Col sm={6}>
                <Row>
                    <Col className="pt-2 text-end">
                        <i className="text-muted">
                            Total Records: {totalGrowhouses}
                        </i>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-count">Count</InputGroup.Text>
                            <Form.Select
                                value={count}
                                onChange={updateCount}
                                aria-label="Count"
                                aria-describedby="search-count">
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default GrowhousesPanel;