import { GrowerData, NotificationType, roundDecimal } from "@growerz/shared";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import useGrowhouse from "../../../hooks/useGrowhouse";

const COLLECTION_URL = process.env.REACT_APP_NFT_IMAGE_URL ?? '';

const GrowerLicense = ({
    swappingMint,
    inList,
    grower,
    show,
    hide
}: {
    swappingMint?: string,
    inList: boolean,
    grower: GrowerData,
    show: (event: any) => void,
    hide: () => void
}) => {
    const { post } = useApi();
    const { growhouseId } = useParams();
    const { addNotification, addErrorNotification } = useNotification();
    const { refreshData } = useGrowhouse();

    async function handleSelect(event: any) {
        let mint = event.currentTarget.value;
        post(`/growhouse/${growhouseId}/addgrower`, {
            mint
        }).then((response) => {
            if (!response.success) {
                addErrorNotification("Action Failed", `Failed to add Grower, ${response.message}`);
                console.error("Failed to add Grower: " + response.message);
            } else {
                addNotification("Action Successful", "Grower added to Growhouse", NotificationType.Success);
                refreshData();
                hide();
            }
        }).catch((error: any) => {
            addErrorNotification("Action Failed", "Failed to add Grower, contact support!");
            console.log("Failed to add Grower: ", error.message);
        });
    }

    async function handleSwap(event: any) {
        let mint = event.currentTarget.value;
        post(`/growhouse/${growhouseId}/swapgrower`, {
            oldMint: swappingMint,
            newMint: mint
        }).then((response) => {
            if (!response.success) {
                addErrorNotification("Action Failed", `Failed to add Grower, ${response.message}`);
                console.error("Failed to add Grower: " + response.message);
            } else {
                addNotification("Action Successful", "Grower added to Growhouse", NotificationType.Success);
                refreshData();
                hide();
            }
        }).catch((error: any) => {
            addErrorNotification("Action Failed", "Failed to add Grower, contact support!");
            console.log("Failed to add Grower: ", error.message);
        });
    }

    const ActionButton = () => {
        if (inList) return <Row className="g-0">
            <Col>
                <Button variant="success" className='w-100 br-0 br-b-7' size={"sm"} onClick={show} value={grower.address}>
                    Swap Grower
                </Button>
            </Col>
        </Row>

        if (swappingMint) {
            return <Row className="g-0">
                <Col>
                    <Button variant="success" className='w-100 br-0 br-b-7' onClick={handleSwap} value={grower.address}>
                        Swap Grower
                    </Button>
                </Col>
            </Row>
        }

        return <Row className="g-0">
            <Col>
                <Button variant="success" className='w-100 br-0 br-b-7' size="lg" onClick={handleSelect} value={grower.address}>
                    Select Grower
                </Button>
            </Col>
        </Row>
    }

    return <Card bg={"dark"} text={"white"} className="growerz-license mb-3">
        <Card.Header className="bg-thc text-white">
            <Row>
                <Col className="fs-6 d-none d-lg-inline-block">
                    Growerz License
                </Col>
                <Col className="text-end fs-6">
                    <span className={`font-thumbs-down rarity-text-${grower.class.name.toLowerCase()}`}>
                        {grower.class.name}
                    </span>
                </Col>
            </Row>
        </Card.Header>
        <Card.Body className="license-bg">
            <Row>
                <Col xs={12} lg={5} xxl={4} className="text-center mb-3 mb-lg-0">
                    <img alt={`Grower #${grower.id}`} src={COLLECTION_URL + grower.id} key={grower.id} className="grower-image" />
                </Col>
                <Col className="fs-8 fs-md-7">
                    <Row className="justify-content-between">
                        <Col>ID:</Col>
                        <Col xs={4} className="text-end">{grower.id}</Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col>Rank:</Col>
                        <Col xs={4} className="text-end">{grower.rank}</Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col>Class:</Col>
                        <Col xs={4} className="text-end">{grower.class.short_name}</Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col>Bonus Plants:</Col>
                        <Col xs={4} className="text-end">{grower.class.plant_boost}</Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col>Bonus Seeds:</Col>
                        <Col xs={4} className="text-end">{grower.class.inventory_boost}</Col>
                    </Row>
                    <Row className="g-0">
                        <Col>Harvest Bonus:</Col>
                        <Col xs={4} className="text-end">{grower.class.harvest_boost}x</Col>
                    </Row>
                    <Row className="g-0">
                        <Col>Watering Bonus:</Col>
                        <Col xs={4} className="text-end">-{roundDecimal(Number(grower.class.water_boost), 1)}HR</Col>
                    </Row>
                </Col>
            </Row>
        </Card.Body>
        <Card.Footer className="p-0">
            <ActionButton />
        </Card.Footer>
    </Card>
}

export default GrowerLicense;