"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.skews = exports.growerNerfs = exports.DEFAULT_WATERTIME = exports.BASE_THC_PAYOUT = void 0;
exports.BASE_THC_PAYOUT = 42;
exports.DEFAULT_WATERTIME = 24;
exports.growerNerfs = {
    1: 1,
    2: 0.5,
    3: 0.35
};
exports.skews = {
    "Common": {
        grower: 0.2,
        plant: 0.1
    },
    "Uncommon": {
        grower: 0.1,
        plant: 0.15
    },
    "Rare": {
        grower: 0.075,
        plant: 0.2
    },
    "Epic": {
        grower: 0.05,
        plant: 0.175
    },
    "Mythic": {
        grower: 0,
        plant: 0.15
    }
};
