import { Container } from "react-bootstrap";
import './styles/app.min.css';

import AppRouter from './components/AppRouter';
import { Footer, Header, MobileWalletButton } from './components/Layout';

const App = () => {
    return (
        <div className="flex-wrapper">
            <Header />
            <Container fluid className='g-0 main-content' data-bs-theme="dark">
                <div className="content text-light">
                    <AppRouter />
                </div>
            </Container>
            <MobileWalletButton />
            <Footer />
        </div>
    );
}

export default App;
