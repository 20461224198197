import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';

interface ScrollableComponentProps {
    scrollEnabled: boolean;
    children: React.ReactNode;
}

const ScrollableComponent: React.FC<ScrollableComponentProps> = ({ scrollEnabled, children }) => {
    const scrollableContainerRef = useRef<HTMLDivElement>(null);
    const [leftmostVisibleChildIndex, setLeftmostVisibleChildIndex] = useState(0);

    useEffect(() => {
        const container = scrollableContainerRef.current;
        const updateLeftmostChild = () => {
            if (!container) return;

            const childrenArray = Array.from(container.children);
            const containerRect = container.getBoundingClientRect();

            const visibleChild = childrenArray.findIndex(child => {
                const childRect = child.getBoundingClientRect();
                return childRect.left >= containerRect.left && childRect.right <= containerRect.right;
            });

            setLeftmostVisibleChildIndex(visibleChild >= 0 ? visibleChild : leftmostVisibleChildIndex);
        };

        container?.addEventListener('scroll', updateLeftmostChild);

        return () => {
            container?.removeEventListener('scroll', updateLeftmostChild);
        };
    }, [leftmostVisibleChildIndex]);

    const scrollToChild = (index: number) => {
        const container = scrollableContainerRef.current;
        if (!container || index < 0 || index >= container.children.length) return;

        const targetChild = container.children[index];
        const containerRect = container.getBoundingClientRect();
        const targetRect = targetChild.getBoundingClientRect();

        const newScrollPosition = targetRect.left - containerRect.left + container.scrollLeft;
        container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
    };

    const scrollLeft = () => scrollToChild(leftmostVisibleChildIndex - 1);
    const scrollRight = () => scrollToChild(leftmostVisibleChildIndex + 1);

    return (
        <div className="scrollable-container">
            <button className={`scroll-btn scroll-btn-left ${scrollEnabled ? '' : 'd-none'}`} onClick={scrollLeft}>&lt;</button>
            <Row ref={scrollableContainerRef} className='items'>
                {children}
            </Row>
            <button className={`scroll-btn scroll-btn-right ${scrollEnabled ? '' : 'd-none'}`} onClick={scrollRight}>&gt;</button>
        </div>
    );
};

export default ScrollableComponent;
