import axios, { AxiosHeaders } from 'axios';
import { getIdentity } from './IdentityService';

const apiHost = process.env.REACT_APP_API_HOST;
const apiUrl = apiHost + '/api';

class ApiService {
    corsHeaders = new AxiosHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
        'version': process.env.REACT_APP_MIN_VERSION ?? '0.0.1'
    });

    async authHeaders() {
        const identity = await getIdentity();
        if (identity && identity.token) {
            this.corsHeaders['Authorization'] = "Bearer " + identity.token;
        }

        return this.corsHeaders;
    }

    public async get(requestUrl: string) {
        let headers = await this.authHeaders();
        let response = await axios.get(apiUrl + requestUrl, { headers });
        return response.data;
    }

    public async post(requestUrl: string, data?: any, file?: boolean) {
        let headers = await this.authHeaders();
        if (file) {
            headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
        }
        let response = await axios.post(apiUrl + requestUrl, (file) ? data : JSON.stringify(data), { headers });
        return response.data;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService();