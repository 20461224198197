// NotificationContext.tsx
import React, { createContext, useContext, useRef } from 'react';
import NotificationContainer from '../components/common/NotificationContainer';
import { NotificationType } from '@growerz/shared';

interface NotificationContextProps {
    addNotification: (header: string, content: string, type: NotificationType) => void;
    addErrorNotification: (header: string, content: string) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider = (props: { children?: React.ReactNode }) => {
    const notificationContainerRef = useRef<NotificationContextProps>();

    const addNotification = (header: string, content: string, type: NotificationType = NotificationType.Message) => {
        notificationContainerRef.current?.addNotification(header, content, type);
    }

    const addErrorNotification = (header: string, content: string) => {
        notificationContainerRef.current?.addNotification(header, content, NotificationType.Error);
    }

    return (
        <NotificationContext.Provider value={{ addNotification, addErrorNotification }}>
            {props.children}
            <NotificationContainer ref={notificationContainerRef} />
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
