import { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { Rarity, calculateMaxSeeds } from "@growerz/shared";
import useGrowhouse from "../../../hooks/useGrowhouse";

import Seed from "./Seed";

const inventoryImg = require('../../../assets/images/game/seed_stash.png');

const SeedInventory = () => {
    const { growhouse, harvestCaps, seeds, checkPlantsStatus } = useGrowhouse();
    const [loading, setLoading] = useState(true);
    const [showing, setShowing] = useState(false);
    const [maxSeeds, setMaxSeeds] = useState(0);

    function show() {
        setShowing(true);
        checkPlantsStatus();
    }

    function hide() {
        setShowing(false);
        checkPlantsStatus();
    }

    useEffect(() => {
        if (growhouse) {
            setMaxSeeds(calculateMaxSeeds(growhouse))
        }
        setLoading(false);
    }, [growhouse, seeds]);

    const InventoryCount = () => {
        if (!seeds) return <>? / {maxSeeds}</>;
        return <span>Capacity: {seeds.length}/{maxSeeds}</span>
    }

    const Seeds = () => {
        if (!seeds || loading || !harvestCaps) {
            return <tr>
                <td colSpan={5}>
                    <div className="text-center">Loading seeds...</div>
                </td>
            </tr>;
        }

        if (seeds.length === 0 && !loading) {
            return <tr>
                <td colSpan={5}>
                    <div className="text-center">No seeds in inventory</div>
                </td>
            </tr>;
        }

        return <>{seeds.sort((a, b) => a.id - b.id).map(seed => {
            let disabled = false;

            switch (seed.strain.rarity.name) {
                case Rarity.COMMON:
                    disabled = harvestCaps.common.seeds;
                    break;
                case Rarity.UNCOMMON:
                    disabled = harvestCaps.uncommon.seeds;
                    break;
                case Rarity.RARE:
                    disabled = harvestCaps.rare.seeds;
                    break;
                case Rarity.EPIC:
                    disabled = harvestCaps.epic.seeds;
                    break;
                case Rarity.MYTHIC:
                    disabled = harvestCaps.mythic.seeds;
                    break;
                default:
                    break;
            }

            return <Seed key={seed.id} seed={seed} disabled={disabled} />
        })}</>
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"lg"}>
            <Modal.Header closeButton closeVariant="white">
                <Row className="justify-content-between w-100">
                    <Col>
                        Growhouse Inventory
                    </Col>
                    <Col className="text-end me-3">
                        <InventoryCount />
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center g-3">
                    <Table striped hover variant="dark" className="text-center">
                        <thead>
                            <tr>
                                <th className="bg-thc text-white d-none d-md-table-cell">#</th>
                                <th className="bg-thc text-white" colSpan={2}>Rarity</th>
                                <th className="bg-thc text-white">Strain</th>
                                <th className="bg-thc text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Seeds />
                        </tbody>
                    </Table>
                </Row>
                <p className="fs-7">
                    <i>
                        <strong>Note:</strong> These seeds can only be planted if your Growhouse has enough
                        space, you haven't hit the upgrade requirements, or if you need more THC to upgrade.
                        You can also transfer them to another Growhouse in your Hub if it has enough space.
                    </i>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        <button className="btn-inventory text-center p-2 w-100 fs-7 fs-lg-6" onClick={show}>
            <img src={inventoryImg} alt="Inventory" height={64} />
            <br />
            Inventory
        </button>
    </>
}

export default SeedInventory;