"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const axios = require('axios');
class ApiHelper {
    constructor(apiUrl) {
        this.corsHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Methods': 'GET',
            'Access-Control-Allow-Headers': 'Content-Type'
        };
        this.apiUrl = apiUrl;
    }
    get(requestUrl, headers) {
        return __awaiter(this, void 0, void 0, function* () {
            let response = yield axios.get(this.apiUrl + requestUrl, { headers: headers !== null && headers !== void 0 ? headers : this.corsHeaders });
            return response.data;
        });
    }
    post(requestUrl, data, headers, file) {
        return __awaiter(this, void 0, void 0, function* () {
            if (file) {
                if (headers)
                    headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
                else
                    this.corsHeaders['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
            }
            let response = yield axios.post(this.apiUrl + requestUrl, (file) ? data : JSON.stringify(data), { headers: headers !== null && headers !== void 0 ? headers : this.corsHeaders });
            return response.data;
        });
    }
}
exports.default = ApiHelper;
