import React, { createContext, useCallback, useContext, useMemo } from 'react';
import axios, { AxiosHeaders } from 'axios';
import { getIdentity } from '../services/IdentityService';
import { CallbackResponse } from '@growerz/shared';

interface ApiContextProps {
    get: (requestUrl: string) => Promise<CallbackResponse>;
    post: (requestUrl: string, data?: any) => Promise<CallbackResponse>;
}

const ApiContext = createContext<ApiContextProps | undefined>(undefined);

export const ApiProvider = (props: { children?: React.ReactNode }) => {
    const apiHost = process.env.REACT_APP_API_HOST;
    const apiUrl = apiHost + '/api';

    const authHeaders = useCallback(async () => {
        const corsHeaders = new AxiosHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Content-Type',
            'version': process.env.REACT_APP_MIN_VERSION ?? '0.0.1'
        });

        const identity = await getIdentity();
        if (identity && identity.token) {
            corsHeaders['Authorization'] = "Bearer " + identity.token;
        }

        return corsHeaders;
    }, []);

    const get = useCallback(async (requestUrl: string) => {
        let headers = await authHeaders();
        let response = await axios.get(apiUrl + requestUrl, { headers });
        return response.data;
    }, [apiUrl, authHeaders]);

    const post = useCallback(async (requestUrl: string, data?: any) => {
        let headers = await authHeaders();
        let response = await axios.post(apiUrl + requestUrl, JSON.stringify(data), { headers });
        return response.data;
    }, [apiUrl, authHeaders]);

    const context = useMemo(() => ({ get, post }), [get, post]);

    return (
        <ApiContext.Provider value={context}>
            {props.children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within a ApiProvider');
    }
    return context;
};
