import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useWallet } from "@solana/wallet-adapter-react";
import { LoadingBackdrop } from "../components/common/Loading";
import GrowhouseHub from "../components/game/GrowhouseHub";

const HomePage = () => {
    const { connected, connecting, publicKey } = useWallet();
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>();

    useEffect(() => {
        if (connecting) {
            setLoading(true);
            setLoadingMessage("Connecting to Solana Blockchain...")
        } else {
            setLoading(false);
            setLoadingMessage(undefined);
        }
    }, [connected, publicKey, connecting]);

    if (connected && publicKey) {
        return <GrowhouseHub />
    }

    return (<Container fluid className="bg-clear p-relative">
        <LoadingBackdrop message={loadingMessage} showing={loading} />
        <Row className="mt-5">
            <Col className="pt-5 text-center fs-5">
                {!connected && !publicKey && !connecting && (<>Welcome to The Growerz Hub</>)}
            </Col>
        </Row>
    </Container>);
}

export default HomePage;