import { forwardRef, useImperativeHandle, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";

interface Notification {
    id: number;
    header: string;
    content: string;
    type: string;
}

interface NotificationContainerProps { }

const NotificationContainer: React.ForwardRefRenderFunction<any, NotificationContainerProps> = (_, ref) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const addNotification = (header: string, content: string, type: string) => {
        const newNotification: Notification = {
            id: Date.now(),
            header,
            content,
            type
        };

        setNotifications((prevNotifications) => [...prevNotifications, newNotification]);

        setTimeout(() => {
            removeNotification(newNotification.id);
        }, 5000);
    }

    const removeNotification = (id: number) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    }

    useImperativeHandle(ref, () => ({
        addNotification,
    }))

    return (
        <ToastContainer
            className="p-3 position-fixed"
            position={"bottom-end"}
            style={{ zIndex: 1 }}
        >
            {notifications.map((notification) => (
                <Toast bg={notification.type} key={notification.id} className="mb-6 mb-md-0" onClose={() => removeNotification(notification.id)}>
                    <Toast.Header closeButton={false}>
                        <strong className="me-auto">
                            {notification.header}
                        </strong>
                    </Toast.Header>
                    <Toast.Body>
                        {notification.content}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
};

export default forwardRef(NotificationContainer);
