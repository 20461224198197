const commonSeed = require("../../assets/images/game/seeds/common.png");
const uncommonSeed = require("../../assets/images/game/seeds/uncommon.png");
const rareSeed = require("../../assets/images/game/seeds/rare.png");
const epicSeed = require("../../assets/images/game/seeds/epic.png");
const mythicSeed = require("../../assets/images/game/seeds/mythic.png");

const SeedImagePreload = [commonSeed,
    uncommonSeed,
    rareSeed,
    epicSeed,
    mythicSeed
]

export default SeedImagePreload;

export const SeedImages = {
    "Common": commonSeed,
    "Uncommon": uncommonSeed,
    "Rare": rareSeed,
    "Epic": epicSeed,
    "Mythic": mythicSeed
}