import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { NotificationType } from "@growerz/shared";

import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import { PencilSquare } from "react-bootstrap-icons";
import useGrowhouse from "../../../hooks/useGrowhouse";

const GrowhouseNickname = ({
    growhouseId,
    nickname
}: {
    growhouseId: number,
    nickname: string
}) => {
    const { post } = useApi();
    const { addNotification, addErrorNotification } = useNotification();
    const { refreshData } = useGrowhouse();
    const [loading, setLoading] = useState(false);
    const [showing, setShowing] = useState(false);
    const [newNickname, setNewNickname] = useState(nickname);

    function show() {
        setShowing(true);
    }

    function hide() {
        setShowing(false);
    }

    function handleSubmit(event: any) {
        if (newNickname === nickname) return;
        setLoading(true);
        post(`/growhouse/${growhouseId}/nickname`, {
            nickname: newNickname
        }).then((response) => {
            if (!response.success) {
                addErrorNotification("Action failed!", `Unable to update Growhouse Nickname, ${response.message}`);
                console.error("Failed to update Growhouse Nickname: " + response.message);
                setLoading(false);
            } else {
                addNotification("Growhouse Nickname Updated!", `Successfully updated Growhouse Nickname!`, NotificationType.Success);
                setLoading(false);
                refreshData();
                hide();
            }
        }).catch((error) => {
            addErrorNotification("Action failed!", 'Unable to update Growhouse Nickname, contact support!');
            console.error("Unable to update Growhouse Nickname: " + error.message);
            setLoading(false);
        });
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"sm"}>
            <Modal.Header closeButton closeVariant="white">
                Growhouse Nickname
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center g-3">
                    <Col>
                        <Form.Group className="mb-3" controlId="editProfileUsername">
                            <Form.Label>Nickname</Form.Label>
                            <Form.Control type="text" placeholder="Nickname" defaultValue={nickname} onChange={(e) => setNewNickname(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="thc" className="text-white mt-3 px-3" onClick={handleSubmit} disabled={loading}>{loading ? 'Saving...' : 'Submit'}</Button>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        {nickname && (<>
            <span className="fs-7 fst-italic">Growhouse # {growhouseId}</span>
            <br />
            <span className="fs-4 fs-md-3">
                {nickname}
                &nbsp;
                <button className="btn-plain fs-5 edit-growhouse-name" onClick={show}>
                    <PencilSquare />
                </button>
            </span>
        </>
        )}
        {!nickname &&
            <span className="fs-4 fs-md-3">
                Growhouse # {growhouseId}
                &nbsp;
                <button className="btn-plain fs-5 edit-growhouse-name" onClick={show}>
                    <PencilSquare />
                </button>
            </span>
        }
    </>
}

export default GrowhouseNickname;