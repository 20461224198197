import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import SolanaService from "../services/SolanaService";

// Define the context
interface WalletBalanceContextProps {
    balance: number;
    tokenBalance: number;
    updateBalance: () => void;
}

const WalletBalanceContext = createContext<WalletBalanceContextProps>({
    balance: 0,
    tokenBalance: 0,
    updateBalance: () => { }
});

// Define the custom hook
export const useWalletBalance = () => {
    const context = useContext(WalletBalanceContext);
    if (!context) {
        throw new Error('useWalletBalance must be used within a WalletProvider');
    }
    return context;
};

// Define the provider component
export const WalletBalanceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { connection } = useConnection();
    const { connected, disconnecting, publicKey } = useWallet();
    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [balance, setBalance] = useState<number>(0);

    const updateBalance = useCallback(() => {
        if (!publicKey) return;

        SolanaService.getWalletBalances(publicKey)
            .then((results) => {
                if (!results) throw new Error();
                setTokenBalance(results.tokenBalance);
                setBalance(results.balance);
            }).catch(() => {
                setTokenBalance(0);
                setBalance(0);
            });
    }, [publicKey]);

    const value = useMemo(() => ({
        balance,
        tokenBalance,
        updateBalance,
    }), [updateBalance, balance, tokenBalance]);

    useEffect(() => {
        if (!publicKey || !connected) return;
        if (disconnecting) {
            setBalance(0);
            setTokenBalance(0);
            return;
        }

        updateBalance();

        const subId = connection.onAccountChange(
            publicKey,
            () => updateBalance(),
            'processed'
        )

        return () => {
            connection.removeAccountChangeListener(
                subId
            )
        }
    }, [publicKey, connected, disconnecting, connection, updateBalance]);

    return (
        <WalletBalanceContext.Provider value={value}>
            {children}
        </WalletBalanceContext.Provider>
    );
};
