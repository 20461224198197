import { Identity } from '@growerz/shared';
import storage from './StorageService';

export const getIdentity = async (): Promise<Identity> => {
    const storageIdentity = await storage.get('identity');
    return JSON.parse(storageIdentity);
}

export function setStorageIdentity(identity: Identity) {
    storage.set('identity', JSON.stringify(identity));
}

export function resetStorageIdentity() {
    storage.remove('identity');
}