import { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { PlantData, plantStateToString, shortenAddress } from "@growerz/shared";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";
import PaginationComponent from "../common/Pagination";
import AddPlantModal from "./modals/AddPlant";
import ViewPlantModal from "./modals/ViewPlant";

const PlantsPanel = () => {
    const { addErrorNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [plants, setPlants] = useState<PlantData[]>();
    const [totalPlants, setTotalPlants] = useState<number>(0);

    const [searchAddress, setSearchAddress] = useState();
    const [searchGrowhouse, setSearchGrowhouse] = useState();
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(25);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.post('/plants/all', {
            growhouse: Number(searchGrowhouse),
            address: searchAddress,
            skip,
            count
        }).then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.total !== totalPlants) setTotalPlants(response.data.total);
            setPlants(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get plant data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, searchAddress, searchGrowhouse, skip, count, totalPlants]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const updateSearchAddress = (event: any) => {
        setSearchAddress(event.currentTarget.value);
        setSkip(0);
    }

    const updateSearchGrowhouse = (event: any) => {
        setSearchGrowhouse(event.currentTarget.value);
        setSkip(0);
    }

    const updateCount = (event: any) => {
        setCount(event.currentTarget.value);
        setSkip(0);
    }

    const handlePageChange = (page: number) => {
        setSkip((page * count) - count);
    };

    const Plants = () => {
        if (loading) return (<tr>
            <td colSpan={10} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!plants || plants.length === 0) return (<tr>
            <td colSpan={10} className="text-center">
                No available data
            </td>
        </tr>)

        return (<>{plants.map((plant: PlantData) =>
            <tr>
                <td>{plant.id}</td>
                <td>{plant.growhouse.id}</td>
                <td>{shortenAddress(plant.growhouse.account.address)}</td>
                <td>{plant.strain.name}</td>
                <td>{plant.strain.rarity.name}</td>
                <td>{plantStateToString(plant.state)}</td>
                <td>{plant.last_watering_time ? new Date(plant.last_watering_time).toLocaleString() : 'N/A'}</td>
                <td>{plant.harvested ? 'true' : 'false'}</td>
                <td>{new Date(plant.date_created).toLocaleString()}</td>
                <td>
                    <ViewPlantModal plant={plant} refresh={refreshData} disabled={loading} />
                </td>
            </tr>)
        }</>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={6}>
                <h3>Plant & Seed Management</h3>
            </Col>
            <Col sm={6} className="text-end">
                <Row className="justify-content-end g-3">
                    <Col sm={2}>
                        <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
                    </Col>
                    <Col sm={3}>
                        <AddPlantModal loading={loading} refreshData={refreshData} />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="mb-3 justify-content-end">
            <Col sm={4}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-address">Address</InputGroup.Text>
                    <Form.Control
                        onChange={updateSearchAddress}
                        defaultValue={searchAddress}
                        placeholder="FkRn..."
                        aria-label="Address"
                        aria-describedby="search-address"
                        size="sm"
                    />
                </InputGroup>
            </Col>
            <Col sm={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-growhouse">Growhouse</InputGroup.Text>
                    <Form.Control
                        min="1"
                        type="number"
                        onChange={updateSearchGrowhouse}
                        defaultValue={searchGrowhouse}
                        placeholder="0"
                        aria-label="Address"
                        aria-describedby="search-address"
                    />
                </InputGroup>
            </Col>
        </Row>
        <Table className="fs-7">
            <thead>
                <tr>
                    <td>ID</td>
                    <td>GH</td>
                    <td>Owner</td>
                    <td>Strain</td>
                    <td>Rarity</td>
                    <td>State</td>
                    <td>Last Water</td>
                    <td>Harvested</td>
                    <td>Date Planted</td>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <Plants />
            </tbody>
        </Table>
        <Row className="justify-content-end">
            <Col>
                <PaginationComponent total={totalPlants} count={count} updateData={handlePageChange} skip={skip} />
            </Col>
            <Col sm={6}>
                <Row>
                    <Col className="pt-2 text-end">
                        <i className="text-muted">
                            Total Records: {totalPlants}
                        </i>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-count">Count</InputGroup.Text>
                            <Form.Select
                                value={count}
                                onChange={updateCount}
                                aria-label="Count"
                                aria-describedby="search-count">
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default PlantsPanel;