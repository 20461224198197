import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useWallet } from "@solana/wallet-adapter-react";
import { GrowhouseData, PaymentMethod } from "@growerz/shared";

import GrowhousePurchaseModal from "./GrowhousePurchaseModal";
import { useWalletBalance } from "../../hooks/useWalletBalance";

const growhouseIcon = require('../../assets/images/game/growhouse.png')

const GrowhousePurchase = ({
    growhouses,
    refresh
}: {
    growhouses: GrowhouseData[],
    refresh: () => void
}) => {
    const { publicKey } = useWallet();
    const { balance, tokenBalance } = useWalletBalance();
    const [qualifies, setQualifies] = useState({
        solana: false,
        thc: false
    });
    const [hubStats, setHubStats] = useState<{
        growhousesUnder10: number,
        growhousesMaxxed: number
    }>();

    const tokenBalanceRequired = 100000;
    const solanaBalanceRequired = 2;

    useEffect(() => {
        if (publicKey) {
            setHubStats({
                growhousesUnder10: growhouses.filter(gh => !gh.max_level).length,
                growhousesMaxxed: growhouses.filter(gh => gh.max_level).length
            });
        }
    }, [growhouses, publicKey]);

    useEffect(() => {
        let qualifications = {
            solana: false,
            thc: false
        }

        if (balance) {
            qualifications.solana = growhouses.every(x => x.max_level === true) && balance > solanaBalanceRequired
        }

        if (tokenBalance) {
            qualifications.thc = growhouses.every(x => x.max_level === true) && tokenBalance > tokenBalanceRequired
        }

        setQualifies(qualifications);
    }, [balance, solanaBalanceRequired, tokenBalance, tokenBalanceRequired, growhouses]);

    return <>
        {hubStats && <div className="text-center full-center">
            <Row className="my-3">
                <Col>
                    <h4>
                        Purchase Growhouse
                    </h4></Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <span className={hubStats.growhousesMaxxed < growhouses.length ? "text-danger" : "text-success"}>{hubStats.growhousesMaxxed}</span> / {growhouses.length} <img src={growhouseIcon} alt="Plants" height={36} /> LVL MAX 10
                </Col>
            </Row>
            <hr />
            <Row className="my-3 text-center justify-content-center">
                <Col>
                    <GrowhousePurchaseModal paymentMethod={PaymentMethod.THC} price={tokenBalanceRequired} qualifies={qualifies.thc} refresh={refresh} />
                </Col>
            </Row>
            <Row>
                <Col>
                    OR
                </Col>
            </Row>
            <Row className="my-3 text-center justify-content-center">
                <Col>
                    <GrowhousePurchaseModal paymentMethod={PaymentMethod.SOL} price={solanaBalanceRequired} qualifies={qualifies.solana} refresh={refresh} />
                </Col>
            </Row>
        </div>}
    </>
}

export default GrowhousePurchase;