import { useCallback, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { AccountData, NotificationType, shortenAddress } from "@growerz/shared";

import AdminApiService from "../../../services/AdminApiService";
import { useNotification } from "../../../contexts/NotificationContext";

const AddAdminModal = ({
    loading,
    refreshData
}: {
    loading: boolean,
    refreshData: () => void
}) => {
    const { addErrorNotification, addNotification } = useNotification();
    const [showing, setShowing] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const [user, setUser] = useState<AccountData>();
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const handleSubmit = useCallback(() => {
        if (!user) {
            addErrorNotification('Form is not complete', "User is missing");
            return;
        }

        setWaiting(true);
        AdminApiService.post('/admins/add', {
            user,
            isSuperAdmin
        }).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification('Success', `Successfully added ${isSuperAdmin ? 'SuperAdmin' : 'Admin'} ${shortenAddress(user.address)}`, NotificationType.Success);
            refreshData();
            hide();
        }).catch((error: any) => {
            addErrorNotification('Failed to add admin', error.message);
        }).finally(() => {
            setWaiting(false);
        })
    }, [user, isSuperAdmin, addNotification, addErrorNotification, refreshData]);

    const hide = () => {
        setShowing(false);
    }

    const show = () => {
        setShowing(true);
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"lg"}>
            <Modal.Header closeButton closeVariant="white">
                <Row className="w-100">
                    <Col>
                        Add Seed
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="user">
                            <Form.Label>User</Form.Label>
                            <Form.Control type="text" onChange={(event: any) => setUser(event.currentTarget.value)} className="bg-dark text-light" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="isSuperAdmin">
                            <Form.Label>Super Admin</Form.Label>
                            <Form.Check onChange={() => setIsSuperAdmin(!isSuperAdmin)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="text-white mt-3 px-3" onClick={handleSubmit} disabled={waiting}>Submit</Button>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Button variant={"success"} className="text-white" onClick={show} disabled={loading}>Add</Button>
    </>;
}

export default AddAdminModal;