const thcLogo = require('../../assets/images/logo192.png');

const LoadingIcon = ({ className }: { className?: string }) => {
    return <img src={thcLogo} alt="THC Labz Growerz" className={`fadeInOut ${className ?? "my-5"}`} />
}

export const LoadingCover = ({ message, showing, className }: { message?: string, showing: boolean, className?: string }) => {
    return <div className="loading-container w-100 h-100" style={{ display: showing ? 'block' : 'none' }}>
        <div className="loading-cover">
            <div className="loading-icon text-white">
                <LoadingIcon className={className ?? "my-4"} />
                <h6>{message ?? 'Loading...'}</h6>
            </div>
        </div>
    </div>
}

export const LoadingBackdrop = ({ message, showing }: { message?: string, showing: boolean }) => {
    return <div className="loading-backdrop" style={{ display: showing ? 'block' : 'none' }}>
        <div className="loading-icon">
            <LoadingIcon />
            <h5>{message ?? 'Loading...'}</h5>
        </div>
    </div>
}