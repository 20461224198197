import { Navigate, Route, Routes } from 'react-router-dom';

import HomePage from '../pages/Home';
import DisconnectPage from '../pages/Disconnect';
import GrowhousePage from '../pages/Growhouse';
import AdminPage from '../pages/Admin';
import AuthTest from '../pages/AuthTest';

const AppRouter = () => {

    return (
        <Routes>
            <Route path='/' element={<HomePage />} />

            <Route path='/growhouse/:growhouseId' element={<GrowhousePage />} />
            <Route path='/disconnect' element={<DisconnectPage />} />
            <Route path='/auth' element={<AuthTest />} />

            <Route path='/admin0' element={<AdminPage />} />

            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    );
}

export default AppRouter;