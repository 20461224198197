import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { GrowhouseData, NotificationType, StrainData, shortenAddress } from "@growerz/shared";

import AdminApiService from "../../../services/AdminApiService";
import { useNotification } from "../../../contexts/NotificationContext";

const AddPlantModal = ({
    loading,
    refreshData
}: {
    loading: boolean,
    refreshData: () => void
}) => {
    const { addErrorNotification, addNotification } = useNotification();
    const [strains, setStrains] = useState<StrainData[]>([]);
    const [growhouses, setGrowhouses] = useState<GrowhouseData[]>([]);
    const [showing, setShowing] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const [strain, setStrain] = useState();
    const [growhouse, setGrowhouse] = useState();

    const handleSubmit = useCallback(() => {
        if (!strain || !growhouse) {
            addErrorNotification('Form is not complete', "Strain or Growhouse is missing");
            return;
        }

        setWaiting(true);
        AdminApiService.post('/plants/add', {
            strain,
            growhouse
        }).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification('Success', `Successfully added seed to ${growhouse}`, NotificationType.Success);
            refreshData();
            hide();
        }).catch((error: any) => {
            addErrorNotification('Failed to add seed', error.message);
        }).finally(() => {
            setWaiting(false);
        })
    }, [strain, growhouse, addNotification, addErrorNotification, refreshData]);

    useEffect(() => {
        AdminApiService.get('/baseinfo').then((response) => {
            if (!response.success) throw new Error(response.message);
            setStrains(response.data.strains);
            setGrowhouses(response.data.growhouses.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
        })
    }, [addErrorNotification]);

    const hide = () => {
        setShowing(false);
    }

    const show = () => {
        setShowing(true);
    }

    const Strains = () => {
        return <Form.Select onChange={(event: any) => setStrain(event.currentTarget.value)} value={strain}>
            <option>Choose</option>
            {strains.map((strain) => <option key={strain.id} value={strain.id}>{strain.name} ({strain.rarity.name})</option>)}
        </Form.Select>
    }

    const Growhouses = () => {
        return <Form.Select onChange={(event: any) => setGrowhouse(event.currentTarget.value)} value={growhouse}>
            <option>Choose</option>
            {growhouses.map((growhouse) => <option key={growhouse.id} value={growhouse.id}>{growhouse.id} ({shortenAddress(growhouse.account.address)})</option>)}
        </Form.Select>
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"lg"}>
            <Modal.Header closeButton closeVariant="white">
                <Row className="w-100">
                    <Col>
                        Add Seed
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="strain">
                            <Form.Label>Strain</Form.Label>
                            <Strains />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="growhouse">
                            <Form.Label>Growhouse</Form.Label>
                            <Growhouses />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="text-white mt-3 px-3" onClick={handleSubmit} disabled={waiting}>Submit</Button>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Button variant={"success"} className="text-white" onClick={show} disabled={loading}>Add Seed</Button>
    </>;
}

export default AddPlantModal;