"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSkew = void 0;
function getSkew(skew) {
    return skews[skew];
}
exports.getSkew = getSkew;
const skews = {
    "Common": {
        grower: 0.2,
        plant: 0.1
    },
    "Uncommon": {
        grower: 0.1,
        plant: 0.15
    },
    "Rare": {
        grower: 0.075,
        plant: 0.2
    },
    "Epic": {
        grower: 0.05,
        plant: 0.175
    },
    "Mythic": {
        grower: 0,
        plant: 0.15
    }
};
