import { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { GrowerData, shortenAddress } from "@growerz/shared";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";
import PaginationComponent from "../common/Pagination";
import EditGrowerzModal from "./modals/EditGrowerz";

const COLLECTION_URL = process.env.REACT_APP_NFT_IMAGE_URL ?? '';

const GrowerzPanel = () => {
    const { addErrorNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [growerz, setGrowerz] = useState<GrowerData[]>();
    const [totalGrowerz, setTotalGrowerz] = useState<number>(0);

    const [searchGrowhouse, setSearchGrowhouse] = useState();
    const [searchId, setSearchId] = useState();
    const [searchAddress, setSearchAddress] = useState();
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(50);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.post('/growerz/all', {
            id: searchId,
            growhouse: searchGrowhouse,
            address: searchAddress,
            skip,
            count
        }).then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.total !== totalGrowerz) setTotalGrowerz(response.data.total);
            setGrowerz(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get Grower data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, searchAddress, searchGrowhouse, searchId, skip, count, totalGrowerz]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const updateSearchId = (event: any) => {
        setSearchId(event.currentTarget.value);
        setSkip(0);
    }

    const updateSearchGrowhouse = (event: any) => {
        setSearchGrowhouse(event.currentTarget.value);
        setSkip(0);
    }

    const updateSearchAddress = (event: any) => {
        setSearchAddress(event.currentTarget.value);
        setSkip(0);
    }

    const updateCount = (event: any) => {
        setCount(event.currentTarget.value);
        setSkip(0);
    }

    const handlePageChange = (page: number) => {
        setSkip((page * count) - count);
    };

    const Growerz = () => {
        if (loading) return (<tr>
            <td colSpan={9} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!growerz || growerz.length === 0) return (<tr>
            <td colSpan={9} className="text-center">
                No available data
            </td>
        </tr>)

        return (<>{growerz.map((grower: GrowerData) =>
            <tr>
                <td>{grower.id}</td>
                <td><img alt={`Grower #${grower.id}`} src={COLLECTION_URL + grower.id} key={grower.id} className="grower-image" height={32} /></td>
                <td>{shortenAddress(grower.address)}</td>
                <td>{grower.rank}</td>
                <td>{grower.class.name}</td>
                <td>{grower.growhouseId ?? 'N/A'}</td>
                <td>{grower.token_address ? shortenAddress(grower.token_address) : 'N/A'}</td>
                <td>{grower.owner ? shortenAddress(grower.owner) : 'N/A'}</td>
                <td>
                    <EditGrowerzModal grower={grower} loading={loading} refreshData={refreshData} />
                </td>
            </tr>)
        }</>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={6}>
                <h3>Grower Management</h3>
            </Col>
            <Col sm={2} className="text-end">
                <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
            </Col>
        </Row>
        <Row className="mb-3 justify-content-end">
            <Col sm={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-id">ID</InputGroup.Text>
                    <Form.Control
                        type="number"
                        onChange={updateSearchId}
                        defaultValue={searchId}
                        placeholder="0"
                        aria-label="ID"
                        aria-describedby="search-id"
                        size="sm"
                    />
                </InputGroup>
            </Col>
            <Col sm={3}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-growhouse">Growhouse</InputGroup.Text>
                    <Form.Control
                        type="number"
                        onChange={updateSearchGrowhouse}
                        defaultValue={searchGrowhouse}
                        placeholder="0"
                        aria-label="Growhouse"
                        aria-describedby="search-growhouse"
                        size="sm"
                    />
                </InputGroup>
            </Col>
            <Col sm={4}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-address">Address</InputGroup.Text>
                    <Form.Control
                        onChange={updateSearchAddress}
                        defaultValue={searchAddress}
                        placeholder="FkRn..."
                        aria-label="Address"
                        aria-describedby="search-address"
                        size="sm"
                    />
                </InputGroup>
            </Col>
        </Row>
        <Table className="fs-7">
            <thead>
                <tr>
                    <th colSpan={2}>ID</th>
                    <th>Address</th>
                    <th>Rank</th>
                    <th>Class</th>
                    <th>Growhouse</th>
                    <th>ATA</th>
                    <th>Owner</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <Growerz />
            </tbody>
        </Table>
        <Row className="justify-content-end">
            <Col>
                <PaginationComponent total={totalGrowerz} count={count} updateData={handlePageChange} skip={skip} />
            </Col>
            <Col sm={6}>
                <Row>
                    <Col className="pt-2 text-end">
                        <i className="text-muted">
                            Total Records: {totalGrowerz}
                        </i>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-count">Count</InputGroup.Text>
                            <Form.Select
                                value={count}
                                onChange={updateCount}
                                aria-label="Count"
                                aria-describedby="search-count">
                                <option value={25} selected={count === 50}>25</option>
                                <option value={50} selected={count === 50}>50</option>
                                <option value={75} selected={count === 50}>75</option>
                                <option value={100} selected={count === 50}>100</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default GrowerzPanel;