import { shortenAddress } from "@growerz/shared";

export const messageFormatter = (message: string) => {
    message = message.replace(/Transaction (.*) confirmed/, (_, transaction) => {
        const truncatedTransaction = `${transaction.substring(0, 10)}...${transaction.substring(transaction.length - 10)}`;
        return `Transaction <a href="https://solscan.io/tx/${transaction}" target="_blank" rel="noreferrer">${truncatedTransaction}</a> confirmed`;
    });

    message = message.replace(/Headers: Bearer (.*)/, (_, bearer) => {
        const truncatedBearer = `${bearer.substring(0, 10)}...`;
        return `Headers: Bearer ${truncatedBearer}`;
    })

    message = message.replace(/Signature (.*) has expired/, (_, signature) => {
        const truncatedSignature = `${signature.substring(0, 10)}...`;
        return `Signature ${truncatedSignature} has expired`;
    });

    message = message.replace(/Admin (.*) gave/, (_, address) => {
        const truncatedAddress = `${shortenAddress(address)}`;
        return `Admin ${truncatedAddress} gave`;
    });

    // Add more replacements here as needed

    return message;
}