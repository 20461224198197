import { useState } from "react";
import { Col } from "react-bootstrap";

import useGrowhouse from "../../../hooks/useGrowhouse";

import GrowerzModal from "./GrowerzModal";
import GrowerLicense from "./GrowerLicense";
import MobileGrowerLicense from "./MobileGrowerLicense";

const Growerz = ({
    maxGrowers,
    mobile
}: {
    maxGrowers: number,
    mobile?: boolean
}) => {
    const { growerz } = useGrowhouse();
    const [showing, setShowing] = useState(false);
    const [oldGrowerMint, setOldGrowerMint] = useState();

    function show(event: any) {
        if (event.currentTarget.value)
            setOldGrowerMint(event.currentTarget.value)
        else
            setOldGrowerMint(undefined);

        setShowing(true);
    }

    function hide() {
        setShowing(false);
    }

    const AddGrowerButton = () => {
        return <Col xs={4} md={12}>
            <button className="dashed-button fs-6 fs-md-4 py-0 px-1 p-md-5 w-100 h-100 mb-0 mb-md-3" onClick={show}>
                Add Grower
                <br />
                +
            </button>
        </Col>
    }

    const GrowerList = () => {
        let list = [];

        for (let i = 0; i < 3; i++) {
            if (growerz[i]) {
                let grower = growerz[i];
                if (mobile)
                    list.push(<Col xs={4} key={i}>
                        <MobileGrowerLicense grower={grower} show={show} hide={hide} key={i} inList={true} swappingMint={oldGrowerMint} />
                    </Col>);
                else
                    list.push(<GrowerLicense grower={grower} show={show} hide={hide} key={i} inList={true} swappingMint={oldGrowerMint} />);
            } else {
                let enabled = i < maxGrowers;
                if (enabled)
                    list.push(<AddGrowerButton key={i} />);
            }
        }

        return <>{list.map((item) => item)}</>;
    }


    if (!growerz) return <Col className="text-center">Loading Growerz...</Col>;

    return <>
        <GrowerzModal showing={showing} hide={hide} oldGrowerMint={oldGrowerMint} mobile={mobile} />
        <GrowerList />
    </>
}

export default Growerz;