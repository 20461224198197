import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { VersionedTransaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { useApi } from "../../contexts/ApiContext";
import { useNotification } from "../../contexts/NotificationContext";
import SolanaService from "../../services/SolanaService";
import { useWalletBalance } from "../../hooks/useWalletBalance";
import { LoadingBackdrop } from "../common/Loading";
import { PaymentMethod, formatCurrency } from "@growerz/shared";

const tokenImg = require('../../assets/images/token.png');
const solanaImg = require('../../assets/images/solana.png');

const GrowhousePurchaseModal = ({
    price,
    qualifies,
    paymentMethod,
    refresh
}: {
    price: number,
    qualifies: boolean,
    paymentMethod: PaymentMethod
    refresh: () => void
}) => {
    const { post } = useApi();
    const { addErrorNotification } = useNotification();
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const { balance, tokenBalance } = useWalletBalance();
    const [purchasing, setPurchasing] = useState(false);
    const [showing, setShowing] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>();

    function show() {
        setShowing(true);
    }

    function hide() {
        setShowing(false);
        refresh();
    }

    async function handlePurchase() {
        if (!sendTransaction) return;
        if (purchasing) return;

        /*if (!qualifies) {
            addErrorNotification("Purchase Failed", "Qualifications are not met to buy another Growhouse!");
            return;
        }

        let balanceToCheck = paymentMethod === PaymentMethod.THC ? tokenBalance : balance;

        if (price > balanceToCheck) {
            addErrorNotification("Purchase failed", "Insufficient funds!");
            return;
        }*/

        if (publicKey) {
            setPurchasing(true);
            setLoadingMessage("Purchasing Growhouse...");

            let timer: NodeJS.Timeout | undefined;
            const startTimer = () => {
                timer = setTimeout(() => {
                    setLoadingMessage("Please be patient...");
                }, 5000);
            };

            const clearTimer = () => {
                if (timer) {
                    clearTimeout(timer);
                    timer = undefined;
                }
            };

            try {
                const transaction = await SolanaService.createPlayerTransaction(publicKey, price, paymentMethod);
                const signature = await sendTransaction(transaction as VersionedTransaction, connection);

                startTimer();

                const response = await post(`/growhouse/purchase`, {
                    transaction: signature
                });

                if (!response.success) throw new Error(response.message);
                show();
            } catch (error: any) {
                addErrorNotification("Action failed!", 'Unable to purchase growhouse, contact support!');
                console.error("Failed to purchase growhouse: " + error.message);
            } finally {
                setPurchasing(false);
                setLoadingMessage(undefined);
                clearTimer();
            }
        }
    }

    if (purchasing) {
        return <Button variant="thc" className="text-white" disabled>
            Waiting...
        </Button>
    }

    return <>
        <LoadingBackdrop showing={purchasing} message={loadingMessage} />
        <Modal variant="dark" show={showing} onHide={hide} size="lg" className="modal-celebrate text-white" dialogClassName={"modal-50vh"} centered>
            <Modal.Body>
                <h1 className="text-center ff-thumbs pt-1 text-white">Growhouse Purchased</h1>
                <Row className="mt-5 pt-5">
                    <Col className="pt-5 text-center">
                        <h3>You just purchased a new Growhouse!</h3>
                    </Col>
                </Row>
                <Row className="mt-5 pt-5">
                    <Col className="text-center">
                        <Button variant="thc" size={'lg'} className="text-white px-3" onClick={hide}>Continue</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        <Button variant="thc" className="text-white" onClick={handlePurchase} disabled={!qualifies || tokenBalance < price}>
            {
                paymentMethod === "thc" && (<>
                    <span className={tokenBalance < price ? "text-danger" : "text-success"}>{formatCurrency(tokenBalance)}</span> / {formatCurrency(price, 0, 0)} <img src={tokenImg} height={24} alt="THC Token" /> $THC
                </>)
            }
            {
                paymentMethod === "sol" && (<>
                    <span className={balance < price ? "text-danger" : "text-success"}>{formatCurrency(balance)}</span> / {formatCurrency(price)} <img src={solanaImg} height={24} alt="SOL Token" /> SOL
                </>)
            }
        </Button>
    </>
}

export default GrowhousePurchaseModal;