import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    NotificationType,
    adjustClientDate,
    roundDecimal
} from "@growerz/shared";
import { DEFAULT_WATERTIME } from "@growerz/shared/common/constants";

// Contexts
import { useApi } from "../contexts/ApiContext";
import { useIdentity } from "../contexts/IdentityContext";
import { useNotification } from "../contexts/NotificationContext";

// Hooks
import useImagePreloader from "../hooks/ImagePreloader";
import useGrowhouse from "../hooks/useGrowhouse";

// Components
import { LoadingBackdrop } from "../components/common/Loading";
import SeedImagePreload from "../components/common/SeedImages";
import PlantImagePreload from '../components/common/PlantImages';
import Growerz from "../components/game/growerz/Growerz";
import Plants from "../components/game/plants/Plants";
import DispensaryModal from "../components/game/dispensary/DispensaryModal";
import GrowhouseNickname from "../components/game/growhouse/GrowhouseNicknameModal";
import PlantHarvestModal from "../components/game/plants/PlantHarvestModal";
import SeedInventoryModal from "../components/game/plants/SeedInventoryModal";
import UpgradeStatus from "../components/game/growhouse/UpgradeStatus";

const actionImg = require('../assets/images/logo192.png');
const disabledImg = require('../assets/images/bw-logo.png');

const growhouseImg = require('../assets/images/game/growhouse.png');
const waterAllImg = require('../assets/images/game/water_all.png');
const disabledWaterAllImg = require('../assets/images/game/water_all_disabled.png');
const harvestAllImg = require('../assets/images/game/harvest_all.png');
const disabledHarvestAllImg = require('../assets/images/game/harvest_all_disabled.png');
const pruneAllImg = require('../assets/images/logo192.png');
const disabledPruneAllImg = require('../assets/images/bw-logo.png');

const images = [
    actionImg,
    disabledImg,
    growhouseImg,
    waterAllImg,
    disabledWaterAllImg,
    harvestAllImg,
    disabledHarvestAllImg,
    pruneAllImg,
    disabledHarvestAllImg
]

const GrowhousePage = () => {
    const { imagesPreloaded: seedImagesPreloaded } = useImagePreloader(SeedImagePreload);
    const { imagesPreloaded: plantImagesPreloaded } = useImagePreloader(PlantImagePreload);
    const { imagesPreloaded } = useImagePreloader(images);

    const { growhouseId } = useParams();
    const navigate = useNavigate();
    const { post } = useApi();
    const { addErrorNotification, addNotification } = useNotification();
    const { connected, connecting } = useWallet();
    const { authenticating, authenticated } = useIdentity();
    const {
        growhouse,
        loadingGrowhouse,
        plants,
        rarity,
        seeds,
        stats,
        actionsDisabled,
        refreshData,
        setLoadingGrowhouse,
        updateRemainingTHC
    } = useGrowhouse();

    const [loadingMessage, setLoadingMessage] = useState<string>();

    const [showingStats, setShowingStats] = useState(false);
    const [lastHarvest, setLastHarvest] = useState(false);
    const [harvestPayout, setHarvestPayout] = useState<string>();

    useEffect(() => {
        if (!growhouseId) navigate('/');
    }, [growhouseId, navigate]);

    useEffect(() => {
        if (connected && authenticated) {
            refreshData(Number(growhouseId));
        }

        if (!connected || !authenticated) return;
        if (!connected && !connecting && !authenticated && !authenticating) navigate('/');
    }, [authenticated, authenticating, connected, connecting, growhouseId, navigate, refreshData]);

    useEffect(() => {
        if (loadingGrowhouse) {
            if (!growhouse) {
                if (!seedImagesPreloaded || !plantImagesPreloaded || !imagesPreloaded) {
                    setLoadingMessage("Loading Growerz Hub...");
                } else {
                    setLoadingMessage("Populating data...");
                }
            }
        } else {
            setLoadingMessage(undefined);
        }
    }, [loadingGrowhouse, growhouse, seedImagesPreloaded, plantImagesPreloaded, imagesPreloaded]);

    const toggleStats = () => {
        setShowingStats(!showingStats);
    }

    const handleWaterAll = () => {
        setLoadingGrowhouse(true);
        setLoadingMessage("Watering all plants...");

        post(`/growhouse/${growhouseId}/waterall`).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification("Plants watered!", `All plants have been watered!`, NotificationType.Success);
            refreshData();
        }).catch((error) => {
            addErrorNotification("Action failed!", 'Unable to water plants, contact support!');
            console.error("Failed to water plants: " + error.message);
        }).finally(() => {
            setLoadingGrowhouse(false);
        });
    }

    const handleHarvestAll = async () => {
        if (loadingGrowhouse) return;

        setLoadingGrowhouse(true);
        setLoadingMessage("Harvesting all plants...");

        setTimeout(() => {
            setLoadingMessage("Please be patient...");
        }, 5000);

        try {
            const response = await post(`/growhouse/${growhouseId}/harvestall`);
            if (!response.success) throw new Error(response.message);

            setLastHarvest(response.data.lastHarvest);
            setHarvestPayout(response.data.payout);
            updateRemainingTHC(response.data.remainingTHC);
        } catch (error: any) {
            addErrorNotification("Action failed", `Unable to harvest all plants, ${error.message}`);
            console.error("Failed to harvest all plants: " + error.message);
        } finally {
            setLoadingGrowhouse(false);
        }
    }

    const handlePruneAll = () => {
        const currentTime = adjustClientDate(new Date());

        setLoadingGrowhouse(true);
        setLoadingMessage("Prune all dead plants...");
        post(`/growhouse/${growhouseId}/pruneall`, {
            currentTime
        }).then((response) => {
            if (!response.success) throw new Error(response.message);
            addNotification("Plants pruned!", `All dead plants have been pruned!`, NotificationType.Success);
            refreshData();
        }).catch((error) => {
            addErrorNotification("Action failed!", 'Unable to prune plants, contact support!');
            console.error("Failed to prune plants: " + error.message);
        }).finally(() => {
            setLoadingGrowhouse(false);
        });
    }

    const resetHarvestPayout = () => {
        setHarvestPayout(undefined);
        setLastHarvest(false);
        refreshData();
    }

    const PruneAllButton = () => {
        return <button
            className="btn-pruneall text-text-center p-2 w-100 fs-7 fs-lg-6"
            onClick={handlePruneAll}
            disabled={actionsDisabled.pruneAll}
        >
            {actionsDisabled.pruneAll ? <img src={disabledPruneAllImg} alt="Prune All" height={64} /> : <img src={pruneAllImg} alt="Prune All" height={64} />}
            <br />
            Prune All
        </button>
    }

    const HarvestAllButton = () => {
        return <button
            className="btn-harvestall text-text-center p-2 w-100 fs-7 fs-lg-6"
            onClick={handleHarvestAll}
            disabled={actionsDisabled.harvestAll}
        >
            {actionsDisabled.harvestAll ? <img src={disabledHarvestAllImg} alt="Harvest All" height={64} /> : <img src={harvestAllImg} alt="Harvest All" height={64} />}
            <br />
            Harvest&nbsp;All
        </button>
    }

    const WaterAllButton = () => {
        return <button className="btn-waterall text-text-center p-2 w-100 fs-7 fs-lg-6"
            onClick={handleWaterAll}
            disabled={actionsDisabled.waterAll}
        >
            {actionsDisabled.waterAll ? <img src={disabledWaterAllImg} alt="Water All" height={64} /> : <img src={waterAllImg} alt="Water All" height={64} />}
            <br />
            Water All
        </button>
    }

    return <Container className="mt-3 growhouse" fluid>
        <LoadingBackdrop message={loadingMessage} showing={loadingGrowhouse} />
        {growhouse && stats && (
            <Row>
                <Col>
                    <Row className="d-flex d-md-none mb-3">
                        <Col>
                            <Button
                                variant="thc"
                                className="text-light text-center w-100"
                                onClick={() => navigate('/')}
                            >
                                Return to Growhouse Hub
                            </Button>
                        </Col>
                    </Row>
                    <div className={`growhouse-card`}>
                        <Card className={`bg-black border-${rarity.toLowerCase()}`}>
                            <Card.Header className={`text-white bg-${rarity.toLowerCase()}`}>
                                <Row className="justify-content-between p-relative">
                                    <Col className="fs-7">
                                        <GrowhouseNickname growhouseId={growhouse.id} nickname={growhouse.nickname} />
                                    </Col>
                                    <Col className="text-end pt-2 mt-0 mt-md-3 pt-md-0 w-50 w-md-25" style={{ position: 'absolute', top: (growhouse.nickname ? -10 : -14), right: 0 }}>
                                        <span className={`font-thumbs-down fs-6 fs-md-4 rarity-text-${rarity.toLowerCase()}`}>
                                            {rarity}
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="p-2">
                                <Row className="justify-content-between">
                                    <Col xs={12} md={3}>
                                        <UpgradeStatus />
                                        <hr />
                                        {stats && (<>
                                            <Button variant="success" className="w-100 text-light d-block d-md-none mb-3" onClick={toggleStats}>
                                                {showingStats ? "Hide Stats" : "Show Stats"}
                                            </Button>
                                            <div className={`${showingStats ? 'd-block' : 'd-none'} d-md-block mt-3`}>
                                                <div className="fs-7">
                                                    <h5>Stats</h5>
                                                    <hr className="mt-0 mb-2" />
                                                    <Row>
                                                        <Col>Max Growerz:</Col>
                                                        <Col className="text-end">{growhouse.growers.length}/{stats.maxGrowers}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Max Plants:</Col>
                                                        <Col className="text-end">{plants.length}/{stats.maxPlants}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Max Seeds:</Col>
                                                        <Col className="text-end">{seeds.length}/{stats.maxSeeds}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Watering Time:</Col>
                                                        <Col className="text-end">{DEFAULT_WATERTIME - Number(stats.wateringTime)}hr</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Harvest Bonus:</Col>
                                                        <Col className="text-end">{roundDecimal(Number(stats.harvestBonus), 4)}x</Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </>)}
                                        <hr className="" />
                                        <h4>Growerz</h4>
                                        <hr className="my-3" />
                                        <div className="d-none d-md-block">
                                            <Growerz maxGrowers={stats.maxGrowers} />
                                        </div>
                                        <Row className="d-flex d-md-none justify-content-center g-1">
                                            <Growerz maxGrowers={stats.maxGrowers} mobile={true} />
                                        </Row>
                                        <hr className="my-3 d-block d-md-none" />
                                    </Col>
                                    <Col xs={12} md={9} className="pt-0 ps-2">
                                        <Row className="justify-content-between">
                                            <Col md={3} lg={2} className="d-none d-md-inline-block mb-3">
                                                <button
                                                    className="btn-growhouse text-text-center p-2 w-50 w-md-100 fs-7 fs-lg-6"
                                                    onClick={() => navigate('/hub')}
                                                >
                                                    <img src={growhouseImg} alt="Grower Hub" height={64} />
                                                    <br />
                                                    Growhouse Hub
                                                </button>
                                            </Col>
                                            <Col xs={12} md={9} lg={6} className="justify-content-end">
                                                <Row className="g-2 w-100 mb-3 justify-content-center">
                                                    <Col>
                                                        <PruneAllButton />
                                                    </Col>
                                                    <Col>
                                                        <HarvestAllButton />
                                                    </Col>
                                                    <Col>
                                                        <WaterAllButton />
                                                    </Col>
                                                    <Col>
                                                        <SeedInventoryModal />
                                                    </Col>
                                                    <Col>
                                                        <DispensaryModal />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr className="mt-0 mb-3" />
                                        <h4>Plants</h4>
                                        <hr />
                                        <Plants />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
        )}
        <PlantHarvestModal payout={harvestPayout} hide={resetHarvestPayout} maxHarvested={lastHarvest} />
    </Container>
}

export default GrowhousePage;