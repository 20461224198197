import { useState, useCallback, useEffect } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { AccountData } from "@growerz/shared";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";
import PaginationComponent from "../common/Pagination";

const UsersPanel = () => {
    const { addErrorNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<AccountData[]>();
    const [totalUsers, setTotalUsers] = useState<number>(0);

    const [bannedOnly, setBannedOnly] = useState<"banned" | "unbanned" | "all">("all");
    const [searchAddress, setSearchAddress] = useState();
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(25);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.post('/users/all', {
            bannedOnly: bannedOnly,
            address: searchAddress,
            skip,
            count
        }).then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.total !== totalUsers) setTotalUsers(response.data.total);
            setUsers(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get user data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, bannedOnly, searchAddress, skip, count, totalUsers]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const updateSearchAddress = (event: any) => {
        setSearchAddress(event.currentTarget.value);
        setSkip(0);
    }

    const updateCount = (event: any) => {
        setCount(event.currentTarget.value);
        setSkip(0);
    }

    const handlePageChange = (page: number) => {
        setSkip((page * count) - count);
    };

    const handleBan = (event: any) => {
        let userId = event.currentTarget.value;
        setLoading(true);
        AdminApiService.post(`/users/${userId}/ban`).then((response) => {
            if (!response.success) throw new Error(response.message!);
        }).catch((error: any) => {
            addErrorNotification("Failed to ban user", error.message);
            console.log("Failed to ban user: ", error.message);
            refreshData()
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleUnban = (event: any) => {
        let userId = event.currentTarget.value;
        setLoading(true);
        AdminApiService.post(`/users/${userId}/unban`).then((response) => {
            if (!response.success) throw new Error(response.message!);
        }).catch((error: any) => {
            addErrorNotification("Failed to ban user", error.message);
            console.log("Failed to ban user: ", error.message);
            refreshData()
        }).finally(() => {
            setLoading(false);
        });
    }

    const toggleBannedOnly = (event: any) => {
        let value = event.currentTarget.value;
        if (value === bannedOnly) return;
        setBannedOnly(value);
        refreshData();
    }

    const Users = () => {
        if (loading) return (<tr>
            <td colSpan={6} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!users || users.length === 0) return (<tr>
            <td colSpan={6} className="text-center">
                No available data
            </td>
        </tr>)

        return (<>{users.map((user: AccountData) =>
            <tr>
                <td>{user.id}</td>
                <td>{user.address}</td>
                <td>{user.banned ? 'true' : 'false'}</td>
                <td>{user.role?.admin ? 'Admin' : 'User'}</td>
                <td>{user.date_created?.toString()}</td>
                <td>
                    {user.banned ? <Button variant={"warning"} size={"sm"} onClick={handleUnban}>Unban</Button> : <Button variant={"danger"} size={"sm"} onClick={handleBan}>Ban</Button>}
                </td>
            </tr>)
        }</>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={6}>
                <h3>User Management</h3>
            </Col>
            <Col sm={2} className="text-end">
                <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
            </Col>
        </Row>
        <Row className="mb-3 justify-content-end text-end">
            <Col sm={3}>
                <ButtonGroup>
                    <Button variant={"thc"} size={"sm"} className='text-white' active={bannedOnly === "banned"} onClick={toggleBannedOnly} value={"banned"}>
                        Banned
                    </Button>
                    <Button variant={"thc"} size={"sm"} className='text-white' active={bannedOnly === "unbanned"} onClick={toggleBannedOnly} value={"unbanned"} defaultChecked={bannedOnly === "unbanned"}>
                        Unbanned
                    </Button>
                    <Button variant={"thc"} size={"sm"} className='text-white' active={bannedOnly === "all"} onClick={toggleBannedOnly} value={"all"} defaultChecked={bannedOnly === "all"}>
                        All
                    </Button>
                </ButtonGroup>
            </Col>
            <Col sm={4}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-address">Address</InputGroup.Text>
                    <Form.Control
                        onChange={updateSearchAddress}
                        defaultValue={searchAddress}
                        placeholder="FkRn..."
                        aria-label="Address"
                        aria-describedby="search-address"
                        size="sm"
                    />
                </InputGroup>
            </Col>
        </Row>
        <Table className="fs-7">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Address</th>
                    <th>Banned</th>
                    <th>Role</th>
                    <th>Date Registered</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <Users />
            </tbody>
        </Table>
        <Row className="justify-content-end">
            <Col>
                <PaginationComponent total={totalUsers} count={count} updateData={handlePageChange} skip={skip} />
            </Col>
            <Col sm={6}>
                <Row>
                    <Col className="pt-2 text-end">
                        <i className="text-muted">
                            Total Records: {totalUsers}
                        </i>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-count">Count</InputGroup.Text>
                            <Form.Select
                                value={count}
                                onChange={updateCount}
                                aria-label="Count"
                                aria-describedby="search-count">
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default UsersPanel;