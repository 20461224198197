import { useWallet } from "@solana/wallet-adapter-react";
import { useState } from "react";
import { useApi } from "../contexts/ApiContext";
import { Transaction } from "@solana/web3.js";
import { Row, Col, Container } from "react-bootstrap";

const AuthTest = () => {
    const { post } = useApi();
    const { publicKey, signTransaction } = useWallet();
    const [transaction, setTransaction] = useState<string>();
    const [signature, setSignature] = useState();

    const createTransaction = async () => {
        if (!publicKey) return;

        const response = await post('/create-transaction', {
            publicKey: publicKey.toBase58()
        });

        setTransaction(response.data);
    }

    const signAndSendTransaction = async () => {
        if (!transaction || !signTransaction) return;

        const transactionObj = Transaction.from(Buffer.from(transaction, 'base64'));

        const signedTransaction = await signTransaction(transactionObj);

        const response = await post('/verify-transaction', {
            signedTransaction: signedTransaction.serialize().toString('base64')
        });

        setSignature(response.data.signature);
    }

    return <Container fluid className="mt-3">
        <Row className="text-center w-50 mx-auto justify-content-center">
            <Col>
                <button onClick={createTransaction}>Create Transaction</button>
                {transaction && <textarea value={transaction} />}
            </Col>
            <Col>
                <button onClick={signAndSendTransaction}>Sign and Send Transaction</button>
                {signature && <textarea value={signature} />}
            </Col>
        </Row>
    </Container>
}

export default AuthTest;