import {
    Cluster,
    Connection,
    LAMPORTS_PER_SOL,
    ParsedAccountData,
    PublicKey,
    SystemProgram,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
    clusterApiUrl
} from "@solana/web3.js";
import { Metaplex } from "@metaplex-foundation/js";
import { MEMO_PROGRAM_ID, createMemoInstruction } from "@solana/spl-memo";
import { TOKEN_PROGRAM_ID, createTransferInstruction } from "@solana/spl-token";

import ApiService from "./ApiService";

const DEFAULT_CLUSTER = process.env.REACT_APP_DEFAULT_CLUSTER as Cluster ?? 'mainnet-beta';
const CONNECTION_PROVIDER = process.env.REACT_APP_CONNECTION_PROVIDER ?? clusterApiUrl(DEFAULT_CLUSTER);
const SPL_TOKEN = process.env.REACT_APP_SPL_TOKEN ?? TOKEN_PROGRAM_ID;
const UPDATE_AUTHORITY = process.env.REACT_APP_UPDATE_AUTHORITY ?? '';
const VAULT = process.env.REACT_APP_HUB_VAULT ?? '';

const SOLANA_ENABLED = true;

class SolanaService {
    connection = new Connection(CONNECTION_PROVIDER, 'confirmed');
    metaplex = new Metaplex(this.connection);

    async getTokenInfo(wallet: PublicKey) {
        const info = await this.connection.getParsedTokenAccountsByOwner(wallet, { mint: new PublicKey(SPL_TOKEN) });
        return info.value[0];
    }

    async getWalletTokenBalance(wallet: PublicKey) {
        let tokenInfo = await this.getTokenInfo(wallet);
        return tokenInfo.account.data.parsed.info.tokenAmount.uiAmount ?? 0;
    }

    async getWalletBalance(wallet: PublicKey) {
        const balance = await this.connection.getBalance(wallet);
        return (balance / LAMPORTS_PER_SOL);
    }

    async getWalletBalances(wallet: PublicKey) {
        return {
            balance: await this.getWalletBalance(wallet),
            tokenBalance: await this.getWalletTokenBalance(wallet)
        }
    }

    async getNftsByWallet(owner: PublicKey) { // Returns everything except mint address
        console.log(`Getting NFTs by owner ${owner.toBase58()}`)
        const update_authority = new PublicKey(UPDATE_AUTHORITY);
        const nfts = await this.metaplex.nfts().findAllByOwner({ owner }).then((nfts) => {
            return nfts.filter((nft) => nft.updateAuthorityAddress.toBase58() === update_authority.toBase58())
        });
        const mints = nfts.map((nft) => nft.address.toBase58());
        const response = await ApiService.post('/growerz', { growerz: mints });
        if (response.success) return response.data;
    }

    async getNftsByOwner(owner: PublicKey) {
        const tokens = await this.connection.getParsedTokenAccountsByOwner(owner, { programId: TOKEN_PROGRAM_ID });
        const mints = tokens.value.filter((e: any) => e.account.data.parsed.info.tokenAmount.uiAmount === 1)
            .map((e: any) => e.account.data.parsed.info.mint);

        const response = await ApiService.post('/growerz', { growerz: mints });
        if (response.success) return response.data;
    }

    async createPlayerTransaction(sender: PublicKey, amount: number, paymentMethod?: string) {
        if (!SOLANA_ENABLED) return "TEST TRANSACTION";

        const latestBlockhash = await this.connection.getLatestBlockhash();

        let instruction: TransactionInstruction;

        if (!paymentMethod || paymentMethod === "thc") {
            const numberDecimals = await this.getNumberDecimals(new PublicKey(SPL_TOKEN));
            const fromTokenInfo = await this.getTokenInfo(sender);
            const fromTokenAddress = fromTokenInfo.pubkey;
            const toTokenInfo = await this.getTokenInfo(new PublicKey(VAULT));
            const toTokenAddress = toTokenInfo.pubkey;

            instruction = createTransferInstruction(
                fromTokenAddress,
                toTokenAddress,
                sender,
                amount * Math.pow(10, numberDecimals),
                [],
                TOKEN_PROGRAM_ID
            );
        } else {
            instruction = SystemProgram.transfer({
                fromPubkey: sender,
                toPubkey: new PublicKey(VAULT),
                lamports: LAMPORTS_PER_SOL * amount,
            })
        }

        const messageLegacy = new TransactionMessage({
            payerKey: sender,
            recentBlockhash: latestBlockhash.blockhash,
            instructions: [instruction],
        }).compileToLegacyMessage();

        return new VersionedTransaction(messageLegacy);
    }

    async createMemoTransaction(sender: PublicKey, message: Uint8Array) {
        const instruction = new TransactionInstruction({
            keys: [
                { pubkey: sender, isSigner: true, isWritable: true },
            ],
            programId: new PublicKey(MEMO_PROGRAM_ID),
            data: Buffer.from(message),
        });

        return instruction;
    }

    async createValidationTransaction(sender: PublicKey) {
        const latestBlockhash = await this.connection.getLatestBlockhash();
        const instructions = [
            createMemoInstruction("THC Labz Authentication", [sender])
        ];

        const messageLegacy = new TransactionMessage({
            payerKey: sender,
            recentBlockhash: latestBlockhash.blockhash,
            instructions,
        }).compileToLegacyMessage();

        return new VersionedTransaction(messageLegacy);
    }

    private async getNumberDecimals(token: PublicKey): Promise<number> {
        const info = await this.connection.getParsedAccountInfo(token);
        return (info.value?.data as ParsedAccountData).parsed.info.decimals as number;
    }
}

const instance = new SolanaService();
export default instance;